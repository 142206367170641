/*  eslint-disable */
import React, { Component } from 'react';

import AdminSidebar from './components/sidebar'

const data = [
    {
        date: "2022-03-20",
        email: "admin@gmail.com",
        name: "Padula Guruge",
        message: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
        attachment: "nope"
    },
    {
        date: "2022-03-20",
        email: "admin@gmail.com",
        name: "Padula Guruge",
        message: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
        attachment: "nope"
    },
    {
        date: "2022-03-20",
        email: "admin@gmail.com",
        name: "Padula Guruge",
        message: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
        attachment: "nope"
    },
    {
        date: "2022-03-20",
        email: "admin@gmail.com",
        name: "Padula Guruge",
        message: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
        attachment: "nope"
    },
    {
        date: "2022-03-20",
        email: "admin@gmail.com",
        name: "Padula Guruge",
        message: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
        attachment: "nope"
    }
]
class AdminOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            orders: [],
        }
    }



    getStyle = (item) => {
        console.log("Get Style: ", item.deleteRequest);
        return {
            backgroundColor: item.deleteRequest ? '#ffa1a1' : '#FFFFFF',
        }
    }

    render() {


        return (
            <div className="bg-light wd-wrapper">
                <AdminSidebar />
                <div className="wrapper-wx" style={{ margin: '25px' }}>
                    <div className="container-fluid" >
                        <div className="row">
                            <div className="col-12">
                                <h5 className="text-dark bold-normal py-5 bg-white shadow-sm px-2 mt-3 rounded">
                                    Hi, Welcome to Grow Green SL (Pvt) Ltd Admin Panel
                                </h5>
                                <div className="col-12 px-0">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="card" >
                                                <img className="card-img-top" src="..." alt="Card image cap" />
                                                <div className="card-body">
                                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="card" >
                                                <img className="card-img-top" src="..." alt="Card image cap" />
                                                <div className="card-body">
                                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*Shipped orders end*/}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }



}



export default AdminOverview;