import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { FilePond, File, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import Modal from 'react-modal';
import Clients from '../clients/client'
import S3 from 'react-aws-s3';
import { Alert } from 'antd';
import JSONCon from './config.json'
import Header from '../shared/hearder'
import Footer from '../shared/footer'
import ProductsJson from '../whatwedo/subproducts.json'
window.Buffer = window.Buffer || require("buffer").Buffer;
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

    },
};

const config = {
    bucketName: JSONCon.bucketName,
    // dirName: 'contact', /* optional */
    region: JSONCon.region,
    accessKeyId: JSONCon.accessKeyId,
    secretAccessKey: JSONCon.secretAccessKey,
    // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
}

function CocoSingleProduct() {
    const [files, setFiles] = useState([])
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [uploadDetails, setUploadDetails] = React.useState({});
    const [items, setItems] = React.useState([]);

    useEffect(() => {
        setItems(ProductsJson.ceylonspicesArray)
    });

    function openModal() {
        setIsOpen(true);
    }



    function closeModal() {
        setIsOpen(false);
    }

    const uplaodFile = () => {
        const ReactS3Client = new S3(config);

        // e.preventdefault()
        console.log(files[0]);

        ReactS3Client
            .uploadFile(files[0].file, 'test0101.pdf')
            .then(data => console.log(data))
            .catch(err => console.error(err))

    }

    return (
        <div>
            <Header />
            <main id="main">


                <section id="about-us" className="about-us">
                    <div className="container" data-aos="fade-up">

                        {/*  */}
                        {/*  */}
                        <div className="row content">
                            {

                                items && items.map((im, index) =>
                                    index % 2 === 0 ?
                                        <div className="row content">
                                            <div className="col-lg-5 mb-4" data-aos="fade-right">
                                                <div className='row'>
                                                    {
                                                        im.imgs && im.imgs.map((dd, index) =>
                                                            <div className='col-md-4 my-2'>
                                                                <div className="member-img">
                                                                    <img src={dd} className="img-fluid" alt="Grow Green SL (Pvt) Ltd" key={index} />
                                                                </div>
                                                            </div>
                                                        )
                                                    }



                                                </div>

                                            </div>
                                            <div className="col-lg-7 pt-4 pt-lg-0 mb-4" data-aos="fade-left">
                                                <h3 className='mb-2'>{im.title} </h3>
                                                <p style={{ textAlign: 'justify' }}>

                                                               </p>
                                                <ul>
                                                    {
                                                        im && im.list.map((dd, index) =>
                                                            <li key={index}><i className="ri-check-double-line"></i>{dd}</li>
                                                        )
                                                    }

                                                </ul>
                                                {
                                                    im && im.button === true ? <button className='btn btn-success' onClick={openModal}>For more orders</button> : ''
                                                }

                                            </div>
                                        </div>

                                        :
                                        <div className="row content">
                                            <div className="col-lg-7 pt-4 pt-lg-0 mb-4" data-aos="fade-left">
                                                <h3 className='mb-2'>{im.title} </h3>

                                                <ul>
                                                    {
                                                        im && im.list.map((dd, index) =>
                                                            <li key={index}><i className="ri-check-double-line"></i>{dd}</li>
                                                        )
                                                    }

                                                </ul>
                                                {
                                                    im && im.button === true ? <button className='btn btn-success' onClick={openModal}>For more orders</button> : ''
                                                }

                                            </div>
                                            <div className="col-lg-5 mb-4" data-aos="fade-right">
                                                <div className='row'>
                                                    {
                                                        im.imgs && im.imgs.map((dd, index) =>
                                                            <div className='col-md-4 my-2'>
                                                                <div className="member-img">
                                                                    <img src={dd} className="img-fluid" alt="Grow Green SL (Pvt) Ltd" key={index} />
                                                                </div>
                                                            </div>
                                                        )
                                                    }


                                                </div>

                                            </div>

                                        </div>
                                )
                            }

                        </div>
                        {/*  */}
                        {/*  */}
                        {/*  */}

                        {/*  */}

                    </div>
                </section>
                {/* <Clients /> */}
                <Modal
                    isOpen={modalIsOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <h2 className='text-bold pt-2 pr-2 pb-2' style={{ fontSize: '23px' }}>Request more order details</h2>
                    {/* <form > */}
                    <div className="form-row">
                        <div className="col-md-12 form-group">
                            <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                            <div className="validate"></div>
                        </div>
                        <div className="col-md-12 form-group">
                            <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                            <div className="validate"></div>
                        </div>
                    </div>

                    <div className="form-group">
                        <input type="text" className="form-control" name="contact_number" id="contact_number" placeholder="Contact Number" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                        <div className="validate"></div>
                    </div>
                    <div className="form-group">
                        <textarea className="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
                        <div className="validate"></div>
                    </div>
                    <FilePond
                        files={files}
                        onupdatefiles={setFiles}
                        allowMultiple={false}
                        maxFiles={1}
                        server={{
                            process: function (fieldName, file, metadata, load, error, progress, abort) {
                                const ReactS3Client = new S3(config);
                                ReactS3Client
                                    .uploadFile(files[0].file, 'test20101.pdf')
                                    .then(data => {
                                        <Alert message="Success Text" type="success" />
                                        setUploadDetails({
                                            key: data.key,
                                            location: data.location
                                        })
                                        load(data.key)
                                        console.log(data)
                                        return true
                                    }
                                    )
                                    .catch(err => {
                                        <Alert message="Error Text" type="error" />
                                        setUploadDetails({
                                            key: null,
                                            location: null
                                        })
                                        console.error(err)
                                        return false
                                    })
                            }
                        }}
                        name="files"
                        labelIdle='Drag & Drop your files or <span className="filepond--label-action">Browse</span>'
                    />
                    <button className='btn btn-md btn-success ' type='submit' onClick={e => uplaodFile(e)}>Submit</button>
                    {/* </form> */}
                </Modal>
            </main>
            <Footer />
        </div>
    );

}

export default CocoSingleProduct;
