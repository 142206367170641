import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Home from './components/home/home'
import ContactUs from './components/contactus/contactus'
import WhatWeDo from './components/whatwedo/whatwedo'
import AboutUs from './components/aboutus/aboutus'
import Header from './components/shared/hearder'
import Footer from './components/shared/footer'
import HowtoHelp from './components/howtohelp/howtohelp'
// import SingleProduct from './components/singleProducts/singleProduct'
import OrderInquries from './components/admin/orderInquries'
import ContactUsInq from './components/admin/conactusInq'
import AdminOverview from './components/admin/overview'
import CocoPoduct from './components/singleProducts/coco'
import Spices from './components/singleProducts/spices'
import SingleSubProduct from './components/whatwedo/subproductSingle'
import AdminSignin from './components/admin/signin'
import CinnamonSubProduct from './components/singleProducts/cinnamon'
import OurServices from './components/ourServices/ourServices'
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/aboutus",
    element: <AboutUs />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/products/:productuname",
    element: <WhatWeDo />,
  },
  // {
  //   path: "/howtohelp",
  //   element: <HowtoHelp />,
  // },
  // {
  //   path: "/singleproduct",
  //   element: <SingleProduct />,
  // },
  {
    path: "/cocoproducts",
    element: <CocoPoduct />,
  },
  {
    path: "/spices",
    element: <Spices />,
  },
  {
    path: "/cinnamon",
    element: <CinnamonSubProduct />,
  },
  {
    path: "/services",
    element: <OurServices />,
  },
  {
    path: "/product/:id",
    element: <SingleSubProduct />,
  },
  {
    path: "/admin",
    element: <AdminSignin />,
  },
  {
    path: "/admin/orders",
    element: <OrderInquries />,
  },
  {
    path: "/admin/contact-us",
    element: <ContactUsInq />,
  },
  {
    path: "/admin/overview",
    element: <AdminOverview />,
  },
  {
    path: "*",
    element: <Home />,
  },
]);
const admin = true
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* {admin && <Header />} */}

    <RouterProvider router={router} />
    {/* {admin && <Footer />} */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
