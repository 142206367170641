import React, { Component, useState, useEffect } from 'react';
import Header from '../shared/hearder'
import Footer from '../shared/footer'
import { Swiper, SwiperSlide, } from "swiper/react";
import { Autoplay } from "swiper";
import { Parallax, Background } from "react-parallax";
import { Link } from 'react-router-dom'
import bg from '../../asserts/aboutus.png'
import vision from '../../asserts/vision.png'
import mission from '../../asserts/mission.png'
import ce1 from '../../asserts/ce1.jpeg'
import ce2 from '../../asserts/ce2.jpeg'
import ce3 from '../../asserts/ce3.jpeg'
import cspices from '../../asserts/cpics.jpg'
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
const insideStyles = {
    fontWeight: '700',
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    color: 'white',
    fontSize: '60px'
};
function AboutUs() {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1500);
    }, []);
    return (
        <div>

            <Header />
            {loading ? (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            ) : (
                <div>
                    
                    <main id="main">

                        <section id="breadcrumbs" className="breadcrumbs about-us-bk-header">

                            <div className="container">
                                <div className="d-flex justify-content-between align-items-center">
                                    <ol style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                        <li><a href="/">Home</a></li>
                                        <li style={{ fontWeight: '600' }}>About us</li>
                                    </ol>
                                </div>



                            </div>
                            <Parallax bgImage={bg} strength={500} style={{ height: '81vh' }}>
                                <div style={{ height: 600 }}>
                                    <div style={insideStyles}>  OUR STORY</div>
                                </div>
                            </Parallax>
                        </section>

                        <section id="about-us" className="about-us">
                            <div className="container" data-aos="fade-up">

                                <div className="row content">

                                    <div className="col-lg-12 pt-4 pt-lg-0 mb-4" data-aos="fade-left">

                                        <p style={{ textAlign: 'justify' }}>

                                            We embarked on a journey to establish a sustainable future by harnessing the bountiful natural resources of Sri Lanka and sharing them with the global community, specifically the rich offerings of coconut and spices. For centuries, Sri Lanka has been renowned for its exceptional and rare spices and herbs that have been traded worldwide. Keeping this in mind, we ventured beyond borders, as Grow Green SL (Pvt) Ltd, to spread the goodness of this magnificent tropical island.
                                            Thereafter, we began sourcing raw materials directly from cultivations, which are famous for having the most ideal soil and climatic conditions for nutrient-rich spice growing.
                                            Today, we are proud to be recognized as one of the leading export companies in the coir & spices industry, providing tailor-made solutions that meet the diverse needs of our customers.
                                        </p>

                                    </div>
                                    <div className="col-lg-6 mb-4 " data-aos="fade-right">
                                        <ul className="cards">
                                            <li className="cards_item">
                                                <div className="card">
                                                    <div className="card_image_abt">
                                                        <img src={vision} alt="mixed vegetable salad in a mason jar." />
                                                    </div>
                                                    <div className="card_content">
                                                        <h2 className="card_title">OUR VISION</h2>
                                                        <div className="card_text">
                                                            <p>                                            Our vision is to become the most trusted and preferred coir and spices exporter in Sri Lanka, by delivering tailor-made solutions that meet the diverse needs of our customers across the world. We aim to maintain our position as a leader in the industry by continuously improving our processes and product offerings, and by staying up-to-date with the latest technological advancements. We strive to build lasting relationships with our customers based on trust, transparency, and a commitment to excellence.
                                                            </p>
                                                            <hr />

                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                        </ul>


                                    </div>
                                    <div className="col-lg-6 mb-4 " data-aos="fade-right">

                                        <ul className="cards">
                                            <li className="cards_item">
                                                <div className="card">
                                                    <div className="card_image_abt">
                                                        <img src={mission} alt="mixed vegetable salad in a mason jar." />
                                                    </div>
                                                    <div className="card_content">
                                                        <h2 className="card_title">OUR MISSION</h2>
                                                        <div className="card_text">
                                                            <p>                                              At Grow Green SL (Pvt) Ltd., our mission is to be the leading coir and spices exporter in Sri Lanka by providing our customers with efficient, cost-effective, and technologically advanced solutions for the hydroponic industry, along with high-quality coir products and authentic spices that meet international standards. We are committed to maintaining our reputation for quality and excellence by continuously improving our manufacturing systems and processes, and providing exceptional customer service.
                                                            </p>
                                                            <hr />

                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                        </ul>

                                    </div>


                                </div>

                            </div>
                           
                        </section>
                            
                        <section id="team" className="team section-bg">
                            <div className="container-fluid">
                                <section className="modified-hero-section">
                                    <div className="modified-card-grid">
                                        <Link className="modified-card" to="/products/all">
                                            <div
                                                className="modified-card__background"
                                                style={{
                                                    backgroundImage:
                                                        'url(https://growgreencontact.s3.us-west-1.amazonaws.com/about1.png)',
                                                }}
                                            ></div>
                                            <div className="modified-card__content">

                                                <h3 className="modified-card__heading">Coco Products</h3>
                                            </div>
                                        </Link>
                                        <Link className="modified-card" to="/products/all">
                                            <div
                                                className="modified-card__background"
                                                style={{
                                                    backgroundImage:
                                                        'url("https://growgreencontact.s3.us-west-1.amazonaws.com/cpics.jpg")',
                                                }}
                                            ></div>
                                            <div className="modified-card__content">

                                                <h3 className="modified-card__heading">Ceylon Spices</h3>
                                            </div>
                                        </Link>
                                        <Link className="modified-card" to="/products/all">
                                            <div
                                                className="modified-card__background"
                                                style={{
                                                    backgroundImage:
                                                        'url(https://growgreencontact.s3.us-west-1.amazonaws.com/precooked.jpg)',
                                                }}
                                            ></div>
                                            <div className="modified-card__content">

                                                <h3 className="modified-card__heading">Pre-Coocked Foods</h3>
                                            </div>
                                        </Link>
                                        <Link className="modified-card" to="/products/all">
                                            <div
                                                className="modified-card__background"
                                                style={{
                                                    backgroundImage:
                                                        'url(https://growgreencontact.s3.us-west-1.amazonaws.com/fruit.jpg)',
                                                }}
                                            ></div>
                                            <div className="modified-card__content">

                                                <h3 className="modified-card__heading">Fruits</h3>
                                            </div>
                                        </Link>
                                        <Link className="modified-card" to="/products/all">
                                            <div
                                                className="modified-card__background"
                                                style={{
                                                    backgroundImage:
                                                        'url(https://growgreencontact.s3.us-west-1.amazonaws.com/veg.jpg)',
                                                }}
                                            ></div>
                                            <div className="modified-card__content">

                                                <h3 className="modified-card__heading">Vegetable</h3>
                                            </div>
                                        </Link>
                                    </div>
                                </section>
                                <div className="row">
                                    <div className="col-lg-12 col-md-6 d-flex align-items-stretch">


                                    </div>







                                </div>

                            </div>
                            <WhatsAppWidget phoneNumber="+94704000191" />

                        </section>



                    </main>
                    <Footer />
                </div>


            )}


        </div>
    );
}


export default AboutUs;