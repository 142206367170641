/*  eslint-disable */
import React, { Component } from 'react';

import AdminSidebar from './components/sidebar'

const data = [
    {
        date:"2022-03-20",
        email:"admin@gmail.com",
        name:"Padula Guruge",
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
        attachment:"nope"
    },
    {
        date:"2022-03-20",
        email:"admin@gmail.com",
        name:"Padula Guruge",
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
        attachment:"nope"
    },
    {
        date:"2022-03-20",
        email:"admin@gmail.com",
        name:"Padula Guruge",
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
        attachment:"nope"
    },
    {
        date:"2022-03-20",
        email:"admin@gmail.com",
        name:"Padula Guruge",
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
        attachment:"nope"
    },
    {
        date:"2022-03-20",
        email:"admin@gmail.com",
        name:"Padula Guruge",
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
        attachment:"nope"
    }
]
class orderInqurie extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            orders: [],
        }
    }



    getStyle = (item) => {
        console.log("Get Style: ", item.deleteRequest);
        return {
            backgroundColor: item.deleteRequest ? '#ffa1a1' : '#FFFFFF',
        }
    }

    render() {


        return (
            <div className="bg-light wd-wrapper">
                <AdminSidebar />
                <div className="wrapper-wx" style={{ margin: '25px' }}>
                    <div className="container-fluid" >
                        <div className="row">
                            <div className="col-12">
                                <h5 className="text-dark bold-normal py-2 bg-white shadow-sm px-2 mt-3 rounded">
                                    Order Inquries
                                </h5>
                                <div className="col-12 px-0">
                                    <div className="card border-0 shadow-sm rounded mt-3 bg-white pb-2">
                                        <div className="table-responsive px-2">
                                            <table className="table table-stripped">
                                                <thead>
                                               
                                                    <tr>
                                                        <th scope="col">No</th>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Message</th>
                                                        <th scope="col">Attachment</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                     data && data.map((dd, index) =>
                                                     <tr key={index}>
                                                        <td><b>{index+1}</b></td>
                                                        <td>{dd.date}</td>
                                                        <td>{dd.email}</td>
                                                        <td>{dd.name}</td>
                                                        <td>{dd.message}</td>
                                                        <td>{dd.attachment}</td>

                                                        </tr>
                                                    
                                                 )
                                                  }
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {/*Shipped orders end*/}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }



}



export default orderInqurie;