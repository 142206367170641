import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { FilePond, File, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import Modal from 'react-modal';
import Clients from '../clients/client'
import { Alert } from 'antd';
import JSONCon from './config.json'
import Header from '../shared/hearder'
import Footer from '../shared/footer'
import ProductsJson from '../whatwedo/subproducts.json'
import { Link } from 'react-router-dom'
import emailjs from 'emailjs-com';
import { S3 } from 'aws-sdk';

window.Buffer = window.Buffer || require("buffer").Buffer;
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)
const config = {
    bucketName: JSONCon.bucketName,
    // dirName: 'contact', /* optional */
    region: JSONCon.region,
    accessKeyId: JSONCon.accessKeyId,
    secretAccessKey: JSONCon.secretAccessKey,
    // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
}
const s3 = new S3(config);

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

    },
};


function CocoSingleProduct() {
    const [files, setFiles] = useState([])
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [uploadDetails, setUploadDetails] = React.useState({});
    const [items, setItems] = React.useState([]);
    const [loading, setLoading] = useState(false);
    const form = useRef();

    const [fileLocation, setFileLocation] = useState('');
    useEffect(() => {
        setLoading(true);
        setItems(ProductsJson.ceylonCinnamon)
        setTimeout(() => {
            setLoading(false);
        }, 1500);
    }, []);

    function openModal() {
        setIsOpen(true);
    }



    function closeModal() {
        setIsOpen(false);
    }

    const uplaodFile = () => {
        const ReactS3Client = new S3(config);

        // e.preventdefault()
        console.log(files[0]);

        ReactS3Client
            .uploadFile(files[0].file, 'test0101.pdf')
            .then(data => console.log(data))
            .catch(err => console.error(err))

    }
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.init("hWdZSxCGrHgK2oik0");
        const emailService = "service_y1zevkp";
        const emailTemplate = "template_n0te9je";
        emailjs.sendForm(emailService, emailTemplate, form.current, "hWdZSxCGrHgK2oik0")
            .then(
                (result) => {
                    console.log('Email sent successfully', result.text);
                    // Reset the form
                    setsenderEmail('');
                    setName('');
                    setSubject('');
                    setContactNumber('');
                    setMessage('');
                    setAttachment(null);
                    setFiles([])
                    setFileLocation('')
                },
                (error) => {
                    console.error('Email could not be sent:', error.text);
                }
            );
    };
    const handleFileUpload = async (file) => {
        try {
            const uniqueFileName = Date.now() + '_' + file.name;
            setSendDis(true)
            const params = {
                Bucket: config.bucketName,
                Key: uniqueFileName,
                Body: file,
            };
            const uploadResult = await s3.upload(params).promise();
            if (uploadResult) {
                setUploadDetails({
                    key: uploadResult.Key,
                    location: uploadResult.Location
                })
                setFileLocation(uploadResult.Location)
                setSendDis(false)
            }
            setSendDis(false)
        } catch (error) {
        }
    };
    return (
        <div>

            <Header />
            {loading ? (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            ) : (
                <div>

                    <main id="main">


                        <section id="about-us" className="about-us">
                            <div className="container" data-aos="fade-up">

                                {/*  */}
                                {/*  */}
                                <div className="row content">
                                    {

                                        items && items.map((im, index) =>
                                            index % 2 === 0 ?
                                                <div className="row content">
                                                    <div className="col-lg-12 pt-4 pt-lg-0 mb-4" data-aos="fade-left">
                                                        <h3 className='mb-2'>{im.title} </h3>
                                                        <p style={{ textAlign: 'justify' }}>

                                                        </p>
                                                        <ul>
                                                            {
                                                                im && im.list.map((dd, index) =>
                                                                    <li key={index}><i className="ri-check-double-line"></i>{dd}</li>
                                                                )
                                                            }

                                                        </ul>
                                                        <div className="col-lg-6 pt-4 pt-lg-0 mb-4" data-aos="fade-left">
                                                            <div className='prodcu-com'>
                                                                <div>
                                                                    {
                                                                        im && im.button === true ? <button className='btn btn-success' onClick={openModal}>Inquire Now</button> : ''
                                                                    }
                                                                </div>
                                                                <div className='contatc-prod'>
                                                                    {
                                                                        im && im.contact === true ? <Link className='btn btn-info' to="/contact-us">Contact us</Link> : ''
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                        {/* {
                                                    im && im.button === true ? <button className='btn btn-success' onClick={openModal}>For more orders</button> : ''
                                                } */}

                                                    </div>
                                                    <div className="col-lg-12 mb-2" data-aos="fade-right">
                                                        <div className='row'>

                                                            <section className="carousel">
                                                                <div className="carousel__container">


                                                                    {
                                                                        im.imgs && im.imgs.map((im, index) =>
                                                                            <div class="carousel-item" key={index}>
                                                                                <img
                                                                                    class="carousel-item__img"
                                                                                    src={`${im}`} alt="Grow Green SL (Pvt) Ltd"
                                                                                />
                                                                                <div class="carousel-item__details">

                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }


                                                                </div>
                                                            </section>
                                                        </div>

                                                    </div>

                                                </div>

                                                :
                                                <div className="row content">
                                                    <div className="col-lg-12 pt-4 pt-lg-0 mb-4" data-aos="fade-left">
                                                        <h3 className='mb-2'>{im.title} </h3>

                                                        <ul>
                                                            {
                                                                im && im.list.map((dd, index) =>
                                                                    <li key={index}><i className="ri-check-double-line"></i>{dd}</li>
                                                                )
                                                            }

                                                        </ul>
                                                        <div className="col-lg-6 pt-4 pt-lg-0 mb-4" data-aos="fade-left">
                                                            <div className='prodcu-com'>
                                                                <div>
                                                                    {
                                                                        im && im.button === true ? <button className='btn btn-success' onClick={openModal}>Inquire Now</button> : ''
                                                                    }
                                                                </div>
                                                                <div className='contatc-prod'>
                                                                    {
                                                                        im && im.contact === true ? <Link className='btn btn-info' to="/contact-us">Contact us</Link> : ''
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                        {/* {
                                                    im && im.button === true ? <button className='btn btn-success' onClick={openModal}>For more orders</button> : ''
                                                } */}

                                                    </div>
                                                    <div className="col-lg-12 mb-4" data-aos="fade-right">
                                                        <div className='row'>

                                                            <section className="carousel">
                                                                <div className="carousel__container">


                                                                    {
                                                                        im.imgs && im.imgs.map((im, index) =>
                                                                            <div class="carousel-item" key={index}>
                                                                                <img
                                                                                    class="carousel-item__img"
                                                                                    src={`${im}`} alt="Grow Green SL (Pvt) Ltd"
                                                                                />
                                                                                <div class="carousel-item__details">

                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }


                                                                </div>
                                                            </section>
                                                        </div>

                                                    </div>

                                                </div>
                                        )
                                    }

                                </div>
                                {/*  */}
                                {/*  */}
                                {/*  */}

                                {/*  */}

                            </div>
                        </section>
                        {/* <Clients /> */}
                        <Modal
                            isOpen={modalIsOpen}
                            // onAfterOpen={afterOpenModal}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                             <form className="php-email-form" onSubmit={sendEmail} ref={form}>
                            <h2 className='text-bold pt-2 pr-2 pb-2' style={{ fontSize: '23px' }}>Request more order details </h2>
                            {/* <form > */}
                            <div className="form-row">
                                <div className="col-md-12 form-group">
                                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                                    <div className="validate"></div>
                                </div>
                                <div className="col-md-12 form-group">
                                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                                    <div className="validate"></div>
                                </div>
                            </div>

                            <div className="form-group">
                                <input type="text" className="form-control" name="contact_number" id="contact_number" placeholder="Contact Number" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                                <div className="validate"></div>
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
                                <div className="validate"></div>
                            </div>
                            <FilePond
                                files={files}
                                allowMultiple={false}
                                maxFiles={1}
                                onupdatefiles={(fileItems) => {
                                    if (fileItems.length > 0) {
                                        handleFileUpload(fileItems[0].file);
                                    }
                                }}

                                name="files"
                                labelIdle='Drag & Drop your files or <span className="filepond--label-action">Browse</span>'
                            />
                            <button className='btn btn-md btn-success ' type='submit' onClick={e => uplaodFile(e)}>Submit</button>
                            {/* </form> */}
                            </form>
                        </Modal>
                    </main>
                    <Footer />
                </div>
            )}


        </div>
    );

}

export default CocoSingleProduct;
