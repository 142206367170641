import React, { Component } from 'react';
import './header.css'
import img from '../../asserts/logowhite.png'
import {Link} from 'react-router-dom'
class TransHeader extends Component {
    constructor() {
        super();
        this.state = {

        };
    }
    render() {
        return (
            <header>
                <div className="nav-wrapper-trans">
                    <div className="logo-container">
                    <Link to={"/"} > <img className="logo" src={img} alt="Logo" />  </Link>
                    </div>
                </div>
            </header>
        );
    }
}


export default TransHeader;