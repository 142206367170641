import React, { Component } from 'react';
import './howtohelp.css'
import Header from '../shared/hearder'
import Footer from '../shared/footer'
class HowtoHelp extends Component {
    constructor() {
        super();
        this.state = {

        };



    }



    render() {
        return (
            <div>
                <Header />
                <main id="main">

                    <section id="breadcrumbs" className="breadcrumbs">
                        <div className="container">

                            <div className="d-flex justify-content-between align-items-center">
                                <h2>How To Help</h2>
                                <ol>
                                    <li><a href="index.html">Home</a></li>
                                    <li>How To Help</li>
                                </ol>
                            </div>

                        </div>
                    </section>

                    <section id="about-us" className="about-us">
                        <div className="container" data-aos="fade-up">

                            <div className="row content">

                                <div className="col-lg-12 pt-4 pt-lg-0 mb-4" data-aos="fade-left">
                                    <h3 className='mb-2'>Volunteer</h3>

                                    <ul>
                                        <li><i className="ri-check-double-line"></i> If you have the spare time, energy or special skills that could assist in our mission we would love to hear from you. </li>
                                        <li><i className="ri-check-double-line"></i> By giving a few hours of your  time and expertise to Mentor, Counsel, conduct workshops, lectures etc you can make a big difference to what we are trying to achieve. We’d be delighted to hear from you and could work around your availability.</li>
                                        <li><i className="ri-check-double-line"></i> If you would like to volunteer please contact us <a href='contact-us'>contact us</a></li>
                                    </ul>
                                    {/* <p className="font-italic">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                        magna aliqua.
                                    </p> */}
                                </div>

                            </div>

                        </div>
                    </section>

                    <section id="team" className="team section-bg">
                        <div className="container">

                            <div className="row">
                                <div className="col-lg-12 pt-4 pt-lg-0 mb-4" data-aos="fade-left">
                                    <h3 className='mb-2'>Donate</h3>
                                </div>
                            </div>
                            <div className="section-title" data-aos="fade-up" style={{ textAlign: 'left' }}>
                                <p className='mb-2 pr-2 pb-1'><b>We welcome your donations</b></p>
                                <p className='mt-2 mb-2 pr-2 pb-1 pt-1'>100% of donations received will be utilised towards projects and beneficiaries as all Administrative costs of the Foundation is being borne by the parents of Joseph Thevan.</p>
                                <p className='mt-2 mb-2 pr-2 pb-1 pt-1'>If you desire you may donate strictly for a specific cause or project. You may even donate in the name of a dearly departed. Tell us how you want your monies utilised.</p>
                                <p className='mt-2 mb-2 pr-2 pb-1 pt-1'>We operate with complete financial Transparency and Accountability. All Donors will be kept informed on how their monies were used. See our financials. If you need further details don’t hesitate to <a href='contactus'>contact us</a> </p>

                                <p className='mt-2 mb-2 pr-2 pb-1 pt-1'><b><i>You may donate by :-</i></b> </p>
                                <ul>
                                    <li className='mt-1 mb-1'>Cheques drawn in favour of <b>JOSEPH THEVAN FOUNDATION</b></li>
                                    <li className='mt-1 mb-1'><b> <i>Transfer to our Bank account. Details as follows :-</i></b>
                                        <table className="table mt-3 p-2">
                                            <tbody>
                                                <tr>  <td>Account Name</td> <td>Joseph Thevan Foundation</td></tr>
                                                <tr>  <td>Account Number</td> <td>1000549292</td></tr>
                                                <tr>  <td>Currency</td> <td>LKR</td></tr>
                                                <tr>  <td>Bank Name</td> <td>Commercial Bank of Ceylon PLC</td></tr>
                                                <tr>  <td>Bank Code</td> <td>7056</td></tr>
                                                <tr>  <td>Branch Name</td> <td>Nugegoda</td></tr>
                                                <tr>  <td>Branch Code</td> <td>020</td></tr>
                                                <tr>  <td>Swift Code</td> <td>CCEYLKLX</td></tr>
                                                <tr>  <td>Type of Account</td> <td>Current Account</td></tr>

                                            </tbody>
                                        </table>

                                    </li>

                                </ul>
                                <p> <b>If you need further information or have any instructions or conditions, please <a href='contact-us'>contact us</a></b></p>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        );
    }
}


export default HowtoHelp;