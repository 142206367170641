/*  eslint-disable */
import React, { Component } from 'react';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import './signin.css'



function contactUs() {
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const navigate = useNavigate();

    useEffect(() => {
      
       const adminLogin =  localStorage.getItem("admin_signin_growgreen");

       if(adminLogin  == "true"){
            navigate("/admin/overview");
        }
      
    }, [])
    

    const onChangeEmail = (e) => {
        setEmail(e.target.value)
    };
    const onChangePassword = (e) => {
        setPassword(e.target.value)
    };

    const login = () =>{
        if(email === 'd@dd.com' && password === 'asd'){
            localStorage.setItem("admin_signin_growgreen", true);
            navigate("/admin/overview");
        }
    }

    return (
        <div className="bg-light wd-wrapper">
            <div className="container admin_bofy" id="container">
                <div className="form-container sign-up-container">

                </div>
                <div className="form-container sign-in-container">
                    <form onSubmit={login}>
                        <h1>Sign in</h1>

                        <input name='email' value={email} type="email" placeholder="Email" onChange={onChangeEmail} />
                        <input name='password' type="password" value={password} placeholder="Password" onChange={onChangePassword} />
                        <button>Sign In</button>
                    </form>
                </div>
                <div className="overlay-container">
                    <div className="overlay">
                        <div className="overlay-panel overlay-right">
                            <h1>Hello, Welcome to the GrowGreenSL Admin Panel!</h1>
                            <p>Grow Green (Pvt) Ltd. is the leading name in the coir and spices market in Sri Lanka with its tailor-made products meeting international quality standards and being preferred by customers across the world.</p>
                        </div>
                    </div>
                </div>
            </div>

            <footer className='admin_footer'>
                <p>
                    Design and Developed <i className="fa fa-heart"></i> by
                    <a className='mx-1' target="_blank" href="https://lucidex.lk">Lucidex</a>

                </p>
            </footer>
        </div>
    )
}



export default contactUs;