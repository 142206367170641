import React, { Component, useState, useEffect } from 'react';
import Header from '../shared/hearder'
import Footer from '../shared/footer'
import { Swiper, SwiperSlide, } from "swiper/react";
import { Autoplay } from "swiper";
import { Parallax, Background } from "react-parallax";
import ce1 from '../../asserts/ce1.jpeg'
import ce2 from '../../asserts/ce2.jpeg'
import ce3 from '../../asserts/ce3.jpeg'
import ce4 from '../../asserts/ce4.png'
import cologs from '../../asserts/cologs.png'
import bg from '../../asserts/ourservice.png'
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
const insideStyles = {
    fontWeight: '700',
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    color: 'white',
    fontSize: '60px'
};

function OurServices() {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1500);
    }, []);
    return (
        <div>

            <Header />
            {loading ? (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            ) : (
                <div>
                  
                    <main id="main">

                        <section id="breadcrumbs" className="breadcrumbs about-us-bk-header">

                            <div className="container">
                                <div className="d-flex justify-content-between align-items-center">
                                    <ol style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                        <li><a href="/">Home</a></li>
                                        <li style={{ fontWeight: '600' }}>Our Services</li>
                                    </ol>
                                </div>



                            </div>
                            <Parallax bgImage={bg} strength={500} style={{ height: '81vh' }}>
                                <div style={{ height: 600 }}>
                                    <div style={insideStyles}>  OUR SERVICES</div>
                                </div>
                            </Parallax>
                        </section>

                        <section id="about-us" className="about-us">
                            <div className="container-fluid" data-aos="fade-up">

                                <div className="blog-card">
                                    <div className="meta">
                                        <div
                                            className="photo"
                                            style={{
                                                backgroundImage:
                                                    'url(https://growgreencontact.s3.us-west-1.amazonaws.com/pack.png)',
                                            }}
                                        ></div>

                                    </div>
                                    <div className="description">
                                        <h1>PACKAGING SERVICES </h1>
                                        <p className='mb-4'>Our packaging services are designed to ensure that our products reach our customers in perfect condition, with their quality and freshness intact.</p>
                                        <ul>
                                            <li style={{ lineHeight: '30px' }}>
                                                We provide a variety of packaging options to meet diverse needs, such as customized labeling and packaging, vacuum packing, and bulk packaging.
                                            </li>
                                            <li style={{ lineHeight: '30px' }}>
                                                Our team of experienced professionals utilizes the latest technology and equipment to package our products to international standards.
                                            </li>
                                            <li style={{ lineHeight: '30px' }}>We ensure that our products are safe and secure during transport, protecting them from damage or contamination.</li>
                                            <li style={{ textAlign: 'justify' }} className="mt-3">
                                                At Grow Green SL (Pvt) Ltd, we take pride in our packaging services, which are an integral part of our commitment to delivering high-quality products to our customers worldwide.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="blog-card alt">
                                    <div className="meta">
                                        <div
                                            className="photo"
                                            style={{
                                                backgroundImage:
                                                    'url(https://growgreencontact.s3.us-west-1.amazonaws.com/qual.png)',
                                            }}
                                        ></div>

                                    </div>
                                    <div className="description">
                                        <h1>QUALITY CONTROL</h1>
                                        <p className='mb-4'>At Grow Green SL (Pvt) Ltd, we believe that quality is everything. It is the foundation of our business, the backbone of our success, and the driving force behind our commitment to excellence. We work tirelessly to ensure that every product we produce meets the highest standards of quality, using only the finest raw materials and cutting-edge technology.</p>
                                        <ul>
                                            <li style={{ lineHeight: '30px' }}>We work closely with our partners to ensure that every coconut and spice used in our production process is of the highest quality.</li>
                                            <li style={{ lineHeight: '30px' }}>We follow strict ISO22000 and SLSI-approved standards when processing our coconut products and spices.</li>
                                            <li style={{ lineHeight: '30px' }}>Our commitment to adhering to these strict quality control standards ensures that our products are of the highest quality.</li>
                                            <li style={{ lineHeight: '30px' }}>We believe that our high-quality products meet the expectations of our customers worldwide.</li>
                                            <li style={{ textAlign: 'justify' }} className="mt-3">
                                                Our commitment to quality and sustainability has earned us a reputation for excellence among our customers worldwide. We are proud to be recognized as a leader in our industry and will continue to work tirelessly to maintain our high standards and provide our customers with the best quality products.
                                            </li>
                                        </ul>
                                        <div className='container' style={{paddingLeft:'0px', marginLeft:'0px'}}>
                                           <div className='col-12' style={{paddingLeft:'0px', marginLeft:'0px'}}>
                                           <div className='row'>
                                                        <div className='col-12'>
                                                        <h6>CERTIFICATES</h6>
                                                        </div>
                                                        <div className='col-3'><img src={ce1} className='img-fluid' style={{height:'100px'}}/></div>
                                                        <div className='col-3'><img src={ce2} className='img-fluid' style={{height:'100px'}}/></div>
                                                        <div className='col-3'><img src={ce3} className='img-fluid' style={{height:'100px'}}/></div>
                                                        <div className='col-3'><img src={ce4} className='img-fluid' style={{height:'100px'}}/></div>
                                                </div>
                                           </div>
                           </div>
                                    </div>
                                </div>

                                <div className="blog-card">
                                   
                                    <div className="description">
                                        
                                        <div className='container' style={{paddingLeft:'0px', marginLeft:'0px'}}>
                                           <div className='col-12' style={{paddingLeft:'0px', marginLeft:'0px'}}>
                                           <div className='row'>
                                  
                                                        <div className='col-12'><img src={cologs} className='img-fluid' style={{height:'65px'}}/></div>
                                                </div>
                                           </div>
                           </div>
                                    </div>
                                </div>

                            </div>
                        </section>

                        {/* <section id="team" className="team section-bg">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                        <div className="member" data-aos="fade-up">
                                            <div className="member-img">
                                                <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/about1.png" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />

                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                        <div className="member" data-aos="fade-up">
                                            <div className="member-img">
                                                <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/about1.png" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />

                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                        <div className="member" data-aos="fade-up">
                                            <div className="member-img">
                                                <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/about3.png" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />

                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                        <div className="member" data-aos="fade-up">
                                            <div className="member-img">
                                                <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/about4.jpg" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />

                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                        <div className="member" data-aos="fade-up">
                                            <div className="member-img">
                                                <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/about5.jpg" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />

                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                        <div className="member" data-aos="fade-up">
                                            <div className="member-img">
                                                <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/about4.jpg" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section> */}
                        <WhatsAppWidget phoneNumber="+94704000191" />


                    </main>
                    <Footer />
                </div>
            )}


        </div>
    );

}


export default OurServices;