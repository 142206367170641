import React, { Component } from 'react';
import img from '../home/img/log.jpeg'
import { Link } from 'react-router-dom'
class Footer extends Component {
    constructor() {
        super();
        this.state = {

        };



    }



    render() {
        return (
            <div>
                <footer id="footer">

                    <div className="footer-top">
                        <div className="container">
                            <div className="row">

                                <div className="col-lg-6 col-md-6 footer-contact">
                                    <h3>Grow Green SL (Pvt) Ltd</h3>
                                    <p>
                                        No.9D/178 <br />
                                        Jayawadanagma<br />
                                        Battaramulla<br />
                                        Sri Lanka <br /><br />
                                        <strong>Phone:</strong>  +94 70 4000 191<br />
                                        <strong>Email:</strong> info@growgreensl.com<br />
                                    </p>
                                </div>

                                <div className="col-lg-6 col-md-6 footer-links">
                                    <h4>Useful Links</h4>
                                    <div className='row'>
                                        <div className='col-md-6 footer-links'>
                                            <ul><li><i className="bx bx-chevron-right"></i> <Link to="/">Home</Link></li>
                                                <li><i className="bx bx-chevron-right"></i> <Link to="/aboutus">About us</Link></li>
                                                <li><i className="bx bx-chevron-right"></i> <Link to="/contact-us">Contact Us</Link></li>

                                            </ul>
                                        </div>

                                        <div className='col-md-6 footer-links'>
                                            <ul><li><i className="bx bx-chevron-right"></i> <Link to="/products/all">Our products</Link></li>
                                                </ul>
                                        </div>
                                    </div>

                                </div>



                                {/* <div className="col-lg-4 col-md-6 footer-newsletter">
                                    <h4>Join Our Newsletter</h4>
                                    <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>

                                </div> */}

                            </div>
                        </div>
                    </div>

                    <div className="container d-md-flex pt-4 pb-3">


                        <div className="social-links text-center text-md-right pt-3 pt-md-0">
                            <a target='_blank' href="https://www.facebook.com/growgreenceylon" className="facebook"><i className="bx bxl-facebook"></i></a>
                            <a target='_blank' href="https://www.facebook.com/growgreenceylon" className="instagram"><i className="bx bxl-instagram"></i></a>
                            <a target='_blank' href="https://www.linkedin.com/company/grow-green-sl/" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                        </div>
                    </div>
                    {/* <div className="container d-md-flex py-1">
                        <a className='footer-cred' href="https://www.growgreensl.com/"  target="_blank">
                            Copyright © 2020 | All Rights Reserved | Grow Green SL (Pvt) Ltd
                        </a>
                        <a className='footer-cred2 mx-4' href="https://lucidex.lk/" target="_blank">
                            Design &amp; Developed By Lucidex (Pvt) Ltd </a>
                    </div> */}
                </footer>
            </div>
        );
    }
}


export default Footer;