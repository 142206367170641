import React, { Component } from 'react';
class Clients extends Component {
    constructor() {
        super();
        this.state = {

        };
    }
    render() {
        return (
           
                 <section id="clients" className="clients">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                            <h2>Clients</h2>
                        </div>

                        <div className="row no-gutters clients-wrap clearfix" data-aos="fade-up">

                            <div className="col-lg-3 col-md-4 col-6">
                                <div className="client-logo">
                                    <img src="assets/img/clients/client-1.png" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-6">
                                <div className="client-logo">
                                    <img src="assets/img/clients/client-2.png" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-6">
                                <div className="client-logo">
                                    <img src="assets/img/clients/client-3.png" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-6">
                                <div className="client-logo">
                                    <img src="assets/img/clients/client-4.png" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-6">
                                <div className="client-logo">
                                    <img src="assets/img/clients/client-5.png" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-6">
                                <div className="client-logo">
                                    <img src="assets/img/clients/client-6.png" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-6">
                                <div className="client-logo">
                                    <img src="assets/img/clients/client-7.png" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-6">
                                <div className="client-logo">
                                    <img src="assets/img/clients/client-8.png" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                </div>
                            </div>

                        </div>

                    </div>
                </section> 
        );
    }
}


export default Clients;