import React, { useState, Component, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { FilePond, File, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import Modal from 'react-modal';
import Clients from '../clients/client'
import S3 from 'react-aws-s3';
import { Alert } from 'antd';
// import JSONCon from './config.json'
import Header from '../shared/hearder'
import Footer from '../shared/footer'
import JsonData from './subproducts.json'
import { WhatsAppWidget } from 'react-whatsapp-widget';
import { Link } from 'react-router-dom'
import 'react-whatsapp-widget/dist/index.css';
window.Buffer = window.Buffer || require("buffer").Buffer;
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

    },
};

// const config = {
//     bucketName: JSONCon.bucketName,
//     // dirName: 'contact', /* optional */
//     region: JSONCon.region,
//     accessKeyId: JSONCon.accessKeyId,
//     secretAccessKey: JSONCon.secretAccessKey,
//     // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
// }

function SingleSubProducts() {
    const [files, setFiles] = useState([])
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [uploadDetails, setUploadDetails] = React.useState({});
    const [item, setItem] = React.useState()
    const [mainItem, setMainItem] = React.useState()
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        
        const pt = window.location.pathname.split("/")[2]
        console.log(pt);
        setItem(pt)
        let data
        switch (pt) {
            case 'cocopeatdisk':
                data = JsonData.cocproducts.cocopeatdisk
                break;
            case 'cocohuskchips':
                data = JsonData.cocproducts.cocohuskchips
                break;
            case 'cocopeatblocks':
                data = JsonData.cocproducts.cocopeatblocks
                break;
            case 'cocogrowbags':
                data = JsonData.cocproducts.cocogrowbags
                break;
            case 'planterbags':
                data = JsonData.cocproducts.planterbags
                break;
            case 'alba':
                data = JsonData.ceylonspices.alba
                break;
            case 'c4andc5':
                data = JsonData.ceylonspices.c4andc5
                break;
            case 'm4andm5':
                data = JsonData.ceylonspices.m4andm5
                break;
            case 'h1andh2':
                data = JsonData.ceylonspices.h1andh2
                break;
            case 'cinnamonpowder':
                data = JsonData.ceylonspices.cinnamonpowder
                break;
            case 'cinnamonquilling':
                data = JsonData.ceylonspices.cinnamonquilling
                break;
            case 'blackpapper':
                data = JsonData.ceylonspices.blackpapper
                break;
            case 'clove':
                data = JsonData.ceylonspices.clove
                break;
            case 'cinnamonoil':
                data = JsonData.ceylonspices.cinnamonoil
                break;
            case 'arecanuts':
                data = JsonData.ceylonspices.arecanuts
                break;
            case 'garcinia':
                data = JsonData.ceylonspices.garcinia
                break;
            case 'cardamom':
                data = JsonData.ceylonspices.cardamom
                break;
            default:
                data = JsonData.cocproducts.cocopeatdisk
                break;
        }
        setMainItem(data)
        setTimeout(() => {
            setLoading(false);
        }, 1500);
    }, []);
    function openModal() {
        setIsOpen(true);
    }



    function closeModal() {
        setIsOpen(false);
    }

    const uplaodFile = () => {
        // const ReactS3Client = new S3(config);

        // e.preventdefault()
        console.log(files[0]);

        // ReactS3Client
        //     .uploadFile(files[0].file, 'test0101.pdf')
        //     .then(data => console.log(data))
        //     .catch(err => console.error(err))

    }

    return (
        <div>

        <Header />
        {loading ? (
            <div className="loader-container">
                <div className="spinner"></div>
            </div>
        ) : (
        <div>
            
            <main id="main">


                <section id="about-us" className="about-us">
                    <div className="container" data-aos="fade-up">

                        {/*  */}
                        {/*  */}
                        <div className="row content">
                            <div className="col-lg-12 pt-4 pt-lg-0 mb-2" data-aos="fade-left">
                                <h3 className='mb-2'>{mainItem && mainItem.title}</h3>
                                <p style={{ textAlign: 'justify' }}>

                                    {mainItem && mainItem.desc}  </p>
                                <ul className='my-6'>
                                    {
                                        mainItem && mainItem.list.map((im, index) =>
                                            <li key={index}><i className="ri-check-double-line"></i>{im}</li>
                                        )
                                    }

                                </ul>




                            </div>

                            <div className="col-lg-12 mb-2" data-aos="fade-right">
                                <div className='row'>

                                    <section className="carousel">
                                        <div className="carousel__container">


                                            {
                                                mainItem && mainItem.imgs.map((im, index) =>
                                                    <div class="carousel-item" key={index}>
                                                        <img
                                                            class="carousel-item__img"
                                                            src={`${im}`} alt="Grow Green SL (Pvt) Ltd"
                                                        />
                                                        <div class="carousel-item__details">

                                                        </div>
                                                    </div>
                                                )
                                            }


                                        </div>
                                    </section>
                                </div>

                            </div>

                            <div className="col-lg-12 pt-4 pt-lg-0 mb-4" data-aos="fade-left">
                                {
                                    mainItem && mainItem.uses !== undefined ?
                                        <>
                                            {

                                                mainItem.uses === true ?
                                                    <>
                                                        <h3 className='mb-2'>{mainItem && mainItem.usesTitle}</h3>
                                                        <p style={{ textAlign: 'justify' }}>

                                                            {mainItem && mainItem.usesDesc}  </p>

                                                    </> : null

                                            }
                                        </> : null
                                }
                                {
                                    mainItem && mainItem.why !== undefined ?
                                        <>
                                            {

                                                mainItem.why === true ?
                                                    <>
                                                        <h3 className='mb-2'>{mainItem && mainItem.whyTitle}</h3>
                                                        <p style={{ textAlign: 'justify' }}>

                                                            {mainItem && mainItem.whyDesc}  </p>

                                                    </> : null

                                            }
                                        </> : null
                                }
                            </div>
                            <div className="col-lg-6 pt-4 pt-lg-0 mb-4" data-aos="fade-left">
                              <div className='prodcu-com'>
                              <div>
                              {
                                    mainItem && mainItem.button === true ? <button className='btn btn-success' onClick={openModal}>Inquire Now</button> : ''
                                }
                              </div>
                               <div className='contatc-prod'>
                               {
                                    mainItem && mainItem.contact === true ? <Link className='btn btn-info' to="/contact-us">Contact us</Link> : ''
                                }
                               </div>
                              </div>
                              
                            </div>
                            {/* <div className="col-lg-2 pt-4 pt-lg-0 mb-4" data-aos="fade-left">
                                {
                                    mainItem && mainItem.contact === true ? <Link className='btn btn-info' to="/contact-us">Contact us</Link> : ''
                                }
                              
                            </div> */}
                        </div>

                    </div>
                </section>
                {/* <Clients /> */}
                <Modal
                    isOpen={modalIsOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <h2 className='text-bold pt-2 pr-2 pb-2' style={{ fontSize: '23px' }}>Request more order details</h2>
                    {/* <form > */}
                    <div className="form-row">
                        <div className="col-md-12 form-group">
                            <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                            <div className="validate"></div>
                        </div>
                        <div className="col-md-12 form-group">
                            <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                            <div className="validate"></div>
                        </div>
                    </div>

                    <div className="form-group">
                        <input type="text" className="form-control" name="contact_number" id="contact_number" placeholder="Contact Number" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                        <div className="validate"></div>
                    </div>
                    <div className="form-group">
                        <textarea className="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
                        <div className="validate"></div>
                    </div>
                    <FilePond
                        files={files}
                        onupdatefiles={setFiles}
                        allowMultiple={false}
                        maxFiles={1}
                        server={{
                            process: function (fieldName, file, metadata, load, error, progress, abort) {
                                const ReactS3Client = new S3();
                                ReactS3Client
                                    .uploadFile(files[0].file, 'test20101.pdf')
                                    .then(data => {
                                        <Alert message="Success Text" type="success" />
                                        setUploadDetails({
                                            key: data.key,
                                            location: data.location
                                        })
                                        load(data.key)
                                        console.log(data)
                                        return true
                                    }
                                    )
                                    .catch(err => {
                                        <Alert message="Error Text" type="error" />
                                        setUploadDetails({
                                            key: null,
                                            location: null
                                        })
                                        console.error(err)
                                        return false
                                    })
                            }
                        }}
                        name="files"
                        labelIdle='Drag & Drop your files or <span className="filepond--label-action">Browse</span>'
                    />
                    <button className='btn btn-md btn-success ' type='submit' onClick={e => uplaodFile(e)}>Submit</button>
                    {/* </form> */}
                </Modal>
                <WhatsAppWidget  phoneNumber="+94704000191" />

            </main>
            <Footer />
        </div>
    )}


    </div>
);

}

export default SingleSubProducts;
