/*  eslint-disable */
import React from "react";
import "./sidebar.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {

    faUser,
    faEnvelopeSquare,
    faBars,
    faTags,
    faBarcode,
    faClipboardCheck,
    faGift,
    faPlusSquare,
    faTachometerAlt,
    faComment,
    faHome,
    faUserSecret,
    faUsers,
    faSuitcase,
    faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";


class AdminSidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            side_bar_toggle: false,
            active: false
        };
    }

    
    render() {
        const active = this.state.active
        return (
            <>
                <nav className="navbar navbar-dark bg-dark py-0 shadow-sm  fixed-top">
                    <span className="navbar-brand mb-0 h6 text-light ml-2">Admin Panel
                        <FontAwesomeIcon
                            onClick={() => this.setState({ side_bar_toggle: !this.state.side_bar_toggle })}
                            icon={faBars} className="ml-4 click show-icon"></FontAwesomeIcon>
                    </span>
                </nav>

                <div className={`sidebar_wrap sidebar-top ${this.state.side_bar_toggle ? 'sidebar_active' : ''}`}>
                    <ul className="sidebar" style={{ paddingLeft: '10px' }}>
                        <li>
                            <Link to="/admin/overview" style={{textDecoration:'none'}}>
                                <h6 className={`categorylink px-2 ${active == 'dashboard' && 'active_category'}`} style={{display:'flex', alignItems:'center', fontSize:'15px'}}>
                                    <FontAwesomeIcon icon={faTachometerAlt} className="mx-3 sidebar-icon" style={{marginRight:'10px'}}></FontAwesomeIcon>Dashboard
                                </h6></Link>
                        </li>
                      

                        <li>
                            <Link to="/admin/orders" style={{textDecoration:'none'}}>
                                <h6 className="categorylink px-2"  style={{display:'flex', alignItems:'center', fontSize:'15px'}}>
                                     <FontAwesomeIcon icon={faUser} className="mx-3 sidebar-icon" style={{marginRight:'10px'}}></FontAwesomeIcon> Order Inquries
                                </h6></Link>
                        </li>
                        <li >
                            <Link to="/admin/contact-us" style={{textDecoration:'none'}}>
                                <h6 className="categorylink px-2" style={{display:'flex', alignItems:'center', fontSize:'15px'}}> 
                                     <FontAwesomeIcon icon={faUsers} className="mx-3 sidebar-icon" style={{marginRight:'10px'}}></FontAwesomeIcon>Contact-us Inquries
                                </h6></Link>
                        </li>
                        <li >
                            <Link to="/" style={{textDecoration:'none'}}>
                                <h6 className={`categorylink px-2`} style={{display:'flex', alignItems:'center', fontSize:'15px'}}> 
                                    <FontAwesomeIcon icon={faHome} className="mx-3 sidebar-icon" style={{marginRight:'10px'}}></FontAwesomeIcon>Go to Main Site
                                </h6></Link>
                        </li>
                        <li
                        >
                            <h6 className={`categorylink px-2 `}  style={{display:'flex', alignItems:'center', fontSize:'15px'}}>
                                <FontAwesomeIcon icon={faSignOutAlt} className="mx-3 sidebar-icon" style={{marginRight:'10px'}}></FontAwesomeIcon>Logout
                            </h6>
                        </li>
                    </ul>
                </div>
            </>
        );
    }
}


export default AdminSidebar;
