import React, { Component } from 'react';
import './header.css'
import img from '../../asserts/logo2.png'
import {Link, NavLink} from 'react-router-dom'
class Header extends Component {
    constructor() {
        super();
        this.state = {

        };
    }
    render() {
        return (
            <header>
                <div className="nav-wrapper">
                    <div className="logo-container">
                    <Link to={"/"} > <img className="logo" src={img} alt="Logo" />  </Link>
                    </div>
                    <nav>
                        <input className="hidden" type="checkbox" id="menuToggle" />
                        <label className="menu-btn" htmlFor="menuToggle">
                            <div className="menu"></div>
                            <div className="menu"></div>
                            <div className="menu"></div>
                        </label>
                        <div className="nav-container">
                            <ul className="nav-tabs">
                                <li className="nav-tab"> <NavLink to='/' className='cli_n' > Home</NavLink></li>
                                <li className="nav-tab"><NavLink to='/aboutus' className='cli_n'>About </NavLink></li>
                                <li className="nav-tab"><NavLink to='/products/all' className='cli_n'>Our Products</NavLink></li>
                                <li className="nav-tab"><NavLink to='/services' className='cli_n'>Our Services</NavLink></li>
                                <li className="nav-tab"><NavLink to='/contact-us' className='cli_n'>Contact us</NavLink></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}


export default Header;