import React, { useState, Component } from 'react'
import ReactDOM from 'react-dom'
import { FilePond, File, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import Modal from 'react-modal';
import Clients from '../clients/client'
import S3 from 'react-aws-s3';
import { Alert } from 'antd';
import JSONCon from './config.json'
import Header from '../shared/hearder'
import Footer from '../shared/footer'
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
window.Buffer = window.Buffer || require("buffer").Buffer;
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

    },
};

const config = {
    bucketName: JSONCon.bucketName,
    // dirName: 'contact', /* optional */
    region: JSONCon.region,
    accessKeyId: JSONCon.accessKeyId,
    secretAccessKey: JSONCon.secretAccessKey,
    // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
}

function CocoSingleProduct() {
    const [files, setFiles] = useState([])
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [uploadDetails, setUploadDetails] = React.useState({});

    function openModal() {
        setIsOpen(true);
    }



    function closeModal() {
        setIsOpen(false);
    }

    const uplaodFile = () => {
        const ReactS3Client = new S3(config);

        // e.preventdefault()
        console.log(files[0]);

        ReactS3Client
            .uploadFile(files[0].file, 'test0101.pdf')
            .then(data => console.log(data))
            .catch(err => console.error(err))

    }

    return (
        <div>
            <Header />
            <main id="main">


                <section id="about-us" className="about-us">
                    <div className="container" data-aos="fade-up">

                        {/*  */}
                        {/*  */}
                        <div className="row content">


                            <div className="col-lg-5 mb-4" data-aos="fade-right">
                                <div className='row'>
                                    <div className='col-md-4 my-2'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/CocoPeatDiscs/b.jpg" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                    <div className='col-md-4 my-2'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/CocoPeatDiscs/y.jpg" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/CocoPeatDiscs/Tablet.webp" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/CocoPeatDiscs/j.jpg" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/CocoPeatDiscs/a.jpg" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/CocoPeatDiscs/y.webp" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-7 pt-4 pt-lg-0 mb-4" data-aos="fade-left">
                                <h3 className='mb-2'>Coconut peat disks</h3>
                                <p style={{ textAlign: 'justify' }}>

                                    Coco Peat Disks are blended potting soil that will expand when wet. They make it easy to mix up your own potted plants' soil. Just place the disc in a bucket or pot, and water will do the rest. These compressed discs are ideal for home and commercial hydroponics, giving excellent results for various potted plants.                                    </p>
                                <ul>
                                    <li><i className="ri-check-double-line"></i>Coconut discs are manufactured by blending coconut husks or coco peat thoroughly. Discs are made using a modern machine. They come in various sizes with different weights and dimensions</li>
                                    <li><i className="ri-check-double-line"></i>They absorb water like a sponge and swell supporting required potting mixtures</li>
                                    <li><i className="ri-check-double-line"></i>They ensure high water retention and porosity</li>
                                    <li><i className="ri-check-double-line"></i>They require no fertilizer in seed germination</li>
                                    <li><i className="ri-check-double-line"></i>They assist floriculturists</li>

                                </ul>
                                <button className='btn btn-success' onClick={openModal}>For more orders</button>

                            </div>
                        </div>
                        {/*  */}
                        {/*  */}
                        {/*  */}
                        <div className="row content">

                            <div className="col-lg-7 pt-4 pt-lg-0 mb-4" data-aos="fade-left">
                                <h3 className='mb-2'>Coconut husks chips</h3>
                                <p style={{ textAlign: 'justify' }}>

                                    Coconut husks are sliced into required sizes to obtain coco chips. They have become an important, natural, economical growing medium. They have replaced fir bark in organic agriculture.                                    </p>
                                <ul>
                                    <li><i className="ri-check-double-line"></i>High water retention and porosity</li>
                                    <li><i className="ri-check-double-line"></i> Have resistance to fungi and is a 100% natural and renewable source</li>
                                    <li><i className="ri-check-double-line"></i> Retain fertilizer efficiency with excellent drainage</li>
                                    <li><i className="ri-check-double-line"></i> Prevent stress after potting</li>
                                    <li><i className="ri-check-double-line"></i> Help inner germination of seeds</li>
                                    <li><i className="ri-check-double-line"></i> They prevent excess saturation and salt</li>
                                    <li><i className="ri-check-double-line"></i> A 100 % natural and renewable material</li>
                                </ul>
                                <button className='btn btn-success' onClick={openModal}>For more orders</button>

                            </div>
                            <div className="col-lg-5 mb-4" data-aos="fade-right">
                                <div className='row'>
                                    <div className='col-md-12 my-2'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/coconuthuskchips/5.png" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                    <div className='col-md-12 my-2'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/coconuthuskchips/3.png" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                    <div className='col-md-8'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/coconuthuskchips/2.png" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/coconuthuskchips/6.jpg" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/*  */}
                        {/*  */}
                        <div className="row content">


                            <div className="col-lg-5 mb-4" data-aos="fade-right">
                                <div className='row'>
                                    <div className='col-md-6 my-2'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/cocopeatblocks/t.jpg" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                    <div className='col-md-6 my-2'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/cocopeatblocks/brick3.jpg" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/cocopeatblocks/5Kg.png" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/cocopeatblocks/j.jpg" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-7 pt-4 pt-lg-0 mb-4" data-aos="fade-left">
                                <h3 className='mb-2'>Coconut peat blocks</h3>
                                <p style={{ textAlign: 'justify' }}>

                                    Bales are manufactured using dried and processed cocopeat which is a spongy cellulose organic medium. It is a natural, environment-friendly growing medium.                                    </p>
                                <ul>
                                    <li><i className="ri-check-double-line"></i>It is rich in antifungal properties</li>
                                    <li><i className="ri-check-double-line"></i>Being rich in natural rooting hormones it helps the healthy growth of roots</li>
                                    <li><i className="ri-check-double-line"></i>Has high water retention and drainage –it can store 8-9 times of weight equal to its weight</li>
                                    <li><i className="ri-check-double-line"></i>Has low compaction which supports porosity</li>
                                    <li><i className="ri-check-double-line"></i>Has high resistance to diseases because of its low humidity</li>
                                    <li><i className="ri-check-double-line"></i>It is an excellent medium for soil mixing and soil conditioning</li>
                                    <li><i className="ri-check-double-line"></i>Ensures warm growth while keeping proper nutrient balance for any cultivation as it stores fertilizer for long periods</li>
                                    <li><i className="ri-check-double-line"></i>Helps drip irrigation for economic cultivation</li>
                                    <li><i className="ri-check-double-line"></i>It is an excellent substitute for peat moss</li>

                                </ul>
                                <button className='btn btn-success' onClick={openModal}>For more orders</button>

                            </div>
                        </div>

                        {/*  */}
                        {/*  */}
                             {/*  */}
                        {/*  */}
                        {/*  */}
                        <div className="row content">

                            <div className="col-lg-7 pt-4 pt-lg-0 mb-4" data-aos="fade-left">
                                <h3 className='mb-2'>Coconut grow bags</h3>
                                
                                <ul>
                                    <li><i className="ri-check-double-line"></i>Coco grow bags are manufactured using natural organic coco peat</li>
                                    <li><i className="ri-check-double-line"></i>Heavily used in hydroponics and soilless cultivation</li>
                                    <li><i className="ri-check-double-line"></i>Stable product withstand for more than 2 years</li>
                                    <li><i className="ri-check-double-line"></i>They are ready to use –just add water, and wait for 20 minutes for the water to absorb to expand to the required level.</li>
                                    <li><i className="ri-check-double-line"></i>They are cost-effective and a 100% natural medium to grow</li>
                                    <li><i className="ri-check-double-line"></i>They are the best medium ever to cultivate</li>
                                    <li><i className="ri-check-double-line"></i>Vegetables: tomatoes, bell pepper, capsicum, Japanese and Chinese cucumber, ginger, strawberry, chilies, mushrooms, courgettes, melons, rice, and many other plants</li>
                                    <li><i className="ri-check-double-line"></i>Cut flowers: carnations, gerbera daisies, roses, etc.</li>
                                    <li><i className="ri-check-double-line"></i>They help to get far better harvests than ever</li>
                                    <li><i className="ri-check-double-line"></i>They are an eco-friendly medium and increase the efficiency of drip irrigation as you can cultivate under less water consumption.</li>
                                    <li><i className="ri-check-double-line"></i>They support floriculturists in obtaining charming flowers in their rose, Carnation or Gerbera cultivations</li>
                                    <li><i className="ri-check-double-line"></i>Best product for semi-hydroponic solution</li>
                                    <li><i className="ri-check-double-line"></i>As different mixtures of materials are used to cater</li>

                                </ul>
                                <button className='btn btn-success' onClick={openModal}>For more orders</button>

                            </div>
                            <div className="col-lg-5 mb-4" data-aos="fade-right">
                                <div className='row'>
                                    <div className='col-md-6 my-2'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/CoCoPeatGrowBags/r.jpg" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                    <div className='col-md-6 my-2'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/CoCoPeatGrowBags/unnamed.jpg" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/CoCoPeatGrowBags/kkk.jpg.png" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/CoCoPeatGrowBags/t.png" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                         {/*  */}
                        {/*  */}
                        <div className="row content">


                            <div className="col-lg-5 mb-4" data-aos="fade-right">
                                <div className='row'>
                                    <div className='col-md-6 my-2'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/Planterbags/t.jpg" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                    <div className='col-md-6 my-2'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/Planterbags/4.png" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/Planterbags/y.png" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/Planterbags/33.png" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="member-img">
                                            <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/Planterbags/n.jpg" className="img-fluid" alt="Grow Green SL (Pvt) Ltd" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-7 pt-4 pt-lg-0 mb-4" data-aos="fade-left">
                                <h3 className='mb-2'>Planter bags</h3>
                                <p style={{ textAlign: 'justify' }}>

                                They are made from 100% natural coco chips and coco peat (coconut chips and peat). They are an excellent eco-friendly growing medium in a limited space. They are specially designed for organic greenhouses, home, and sky gardening, and any other economical cultivation.                                    </p>
                                <ul>
                                    <li><i className="ri-check-double-line"></i>It is rich in antifungal properties</li>
                                    <li><i className="ri-check-double-line"></i>Being rich in natural rooting hormones it helps the healthy growth of roots</li>
                                    <li><i className="ri-check-double-line"></i>Has high water retention and drainage –it can store 8-9 times of weight equal to its weight</li>
                                    <li><i className="ri-check-double-line"></i>Has low compaction which supports porosity</li>
                                    <li><i className="ri-check-double-line"></i>Has high resistance to diseases because of its low humidity</li>
                                    <li><i className="ri-check-double-line"></i>It is an excellent medium for soil mixing and soil conditioning</li>
                                    <li><i className="ri-check-double-line"></i>Ensures warm growth while keeping proper nutrient balance for any cultivation as it stores fertilizer for long periods</li>
                                    <li><i className="ri-check-double-line"></i>Helps drip irrigation for economic cultivation</li>
                                    <li><i className="ri-check-double-line"></i>It is an excellent substitute for peat moss</li>                                 

                                </ul>
                                <button className='btn btn-success' onClick={openModal}>For more orders</button>

                            </div>
                        </div>

                        {/*  */}
                        {/*  */}

                    </div>
                </section>
                {/* <Clients /> */}
                <Modal
                    isOpen={modalIsOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <h2 className='text-bold pt-2 pr-2 pb-2' style={{ fontSize: '23px' }}>Request more order details</h2>
                    {/* <form > */}
                    <div className="form-row">
                        <div className="col-md-12 form-group">
                            <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                            <div className="validate"></div>
                        </div>
                        <div className="col-md-12 form-group">
                            <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                            <div className="validate"></div>
                        </div>
                    </div>

                    <div className="form-group">
                        <input type="text" className="form-control" name="contact_number" id="contact_number" placeholder="Contact Number" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                        <div className="validate"></div>
                    </div>
                    <div className="form-group">
                        <textarea className="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
                        <div className="validate"></div>
                    </div>
                    <FilePond
                        files={files}
                        onupdatefiles={setFiles}
                        allowMultiple={false}
                        maxFiles={1}
                        server={{
                            process: function (fieldName, file, metadata, load, error, progress, abort) {
                                const ReactS3Client = new S3(config);
                                ReactS3Client
                                    .uploadFile(files[0].file, 'test20101.pdf')
                                    .then(data => {
                                        <Alert message="Success Text" type="success" />
                                        setUploadDetails({
                                            key: data.key,
                                            location: data.location
                                        })
                                        load(data.key)
                                        console.log(data)
                                        return true
                                    }
                                    )
                                    .catch(err => {
                                        <Alert message="Error Text" type="error" />
                                        setUploadDetails({
                                            key: null,
                                            location: null
                                        })
                                        console.error(err)
                                        return false
                                    })
                            }
                        }}
                        name="files"
                        labelIdle='Drag & Drop your files or <span className="filepond--label-action">Browse</span>'
                    />
                    <button className='btn btn-md btn-success ' type='submit' onClick={e => uplaodFile(e)}>Submit</button>
                    {/* </form> */}
                </Modal>
                <WhatsAppWidget  phoneNumber="+94704000191" />

            </main>
            <Footer />
        </div>
    );

}

export default CocoSingleProduct;
