import React, { Component, useState, useEffect } from 'react';
import Header from '../shared/hearder'
import Footer from '../shared/footer'
import { Link, useNavigate } from 'react-router-dom'
import backgroundImage from '../../asserts/bk1.jpg'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useLocation } from "react-router";
import { Parallax, Background } from "react-parallax";

// import bg from '../../asserts/ourproducts1.jpg'
import bg from '../../asserts/new.png'
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
const insideStyles = {
    fontWeight: '700',
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    color: 'white',
    fontSize: '60px'
};

function WhatWeDo() {
    const [tabIndex, setTabIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }, []);
    const checkLoadItem = () => {
        const pt = window.location.pathname.split("/")[2]
        console.log(tabIndex);
        switch (pt) {
            case 'coco-products':
                if (window.history.state !== undefined && window.history.state.page_id !== undefined) {
                    setTabIndex(window.history.state.page_id)
                } else {
                    setTabIndex(0)
                }

                break;
            case 'spices':
                if (window.history.state !== undefined && window.history.state.page_id !== undefined) {
                    setTabIndex(window.history.state.page_id)
                } else {
                    setTabIndex(1)
                }
                break;
            case 'pre-cooked':
                if (window.history.state !== undefined && window.history.state.page_id !== undefined) {
                    setTabIndex(window.history.state.page_id)
                } else {
                    setTabIndex(2)
                }
                break;
            case 'fruits':
                if (window.history.state !== undefined && window.history.state.page_id !== undefined) {
                    setTabIndex(window.history.state.page_id)
                } else {
                    setTabIndex(3)
                }
                break;
            case 'vegetables':
                if (window.history.state !== undefined && window.history.state.page_id !== undefined) {
                    setTabIndex(window.history.state.page_id)
                } else {
                    setTabIndex(4)
                }
            case 'all':
                if (window.history.state !== undefined && window.history.state.page_id !== undefined) {
                    setTabIndex(window.history.state.page_id)
                } else {
                    setTabIndex(tabIndex)
                }
                break;

            default:

                setTabIndex(tabIndex)
                break;
        }
    }
    useEffect(() => {
        checkLoadItem()
    }, [])

    const selectIndex = (index) => {
        window.history.pushState({ page_id: index }, '', window.location)
        setTabIndex(index)
    }

    return (
        
        <div>
        
            <Header />
              {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
      ) : ( <div> <main id="main">
      <section id="breadcrumbs" className="breadcrumbs about-us-bk-header">

              <div className="container">
                  <div className="d-flex justify-content-between align-items-center">
                      <ol style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                          <li><a href="/">Home</a></li>
                          <li style={{ fontWeight: '600' }}>Our Products</li>
                      </ol>
                  </div>



              </div>
              <Parallax bgImage={bg} strength={500} style={{height:'81vh'}}>
                  <div style={{ height: 600 }}>
                      <div style={insideStyles}>  OUR PRODUCTS</div>
                  </div>
              </Parallax>
          </section>
      <div className="container" style={{ marginTop: '40px' }}>
          <Tabs selectedIndex={tabIndex} onSelect={(index) => selectIndex(index)}>
              <TabList style={{ marginBottom: '40px' }}>
                  <Tab><li className="filter-active">  <div className="icon-box" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* <i className="ri-store-line" style={{ color: '#ffbb2c' }}></i> */}
                      <p className='mx-3 mb-0'>Coco Products</p>
                  </div></li> </Tab>
                  <Tab><li data-filter=".ceylon-spices">
                      <div className="icon-box" style={{ display: 'flex', alignItems: 'center' }}>
                          {/* <i className="ri-base-station-line" style={{ color: '#ff5828' }}></i> */}
                          <p className='mx-3 mb-0'>Ceylon Spices</p>
                      </div>

                  </li></Tab>
                  <Tab>  <li data-filter=".pre-cooked">  <div className="icon-box" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* <i className="ri-fingerprint-line" style={{ color: '#29cc61' }}></i> */}
                      <p className='mx-3 mb-0'>Pre-Coocked Foods</p>
                  </div></li></Tab>
                  <Tab>  <li data-filter=".fruits">  <div className="icon-box" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* <i className="ri-anchor-line" style={{ color: '#b2904f' }}></i> */}
                      <p className='mx-3 mb-0'>Fruits</p>
                  </div></li></Tab>
                  <Tab><li data-filter=".vegitables">  <div className="icon-box" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* <i className="ri-paint-brush-line" style={{ color: '#e361ff' }}></i> */}
                      <p className='mx-3 mb-0'>Vegitables</p>
                  </div></li></Tab>
              </TabList>

              <TabPanel>
                  <div className="row portfolio-container" >
                      <div className="col-lg-12 col-md-6 portfolio-item ">
                          <p style={{ textAlign: 'justify', fontWeight: 'bold' }}>

                              We are proud to offer a diverse range of high-quality coco-based products that are sourced from natural materials. Our products include coco peat discs, coco grow bags, and coco husk chips, coco peat blocks and planter bags, all of which are perfect for gardening and horticulture.

                          </p>
                      </div>
                      <div className="col-lg-4 col-md-6 portfolio-item ">
                          <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/CocoPeatDiscs/b.jpg" className="img-fluid tab-view-img" style={{ height: '281px !important' }} alt="Grow Green SL (Pvt) Ltd" />
                          <div className="portfolio-info">
                              <h4>Coconut peat disks</h4>
                              <Link to={"/product/cocopeatdisk"} className=" preview-link" ><i className="bx bx-plus"></i> </Link>

                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6 portfolio-item ">
                          <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/CoCoPeatGrowBags/r.jpg" className="img-fluid tab-view-img" style={{ height: '281px !important' }} alt="Grow Green SL (Pvt) Ltd" />
                          <div className="portfolio-info">
                              <h4>Coconut grow bags</h4>
                              <Link to={"/product/cocogrowbags"} className=" preview-link" ><i className="bx bx-plus"></i> </Link>

                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6 portfolio-item ">
                          <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/coconuthuskchips/5.png" className="img-fluid tab-view-img" style={{ height: '281px !important' }} alt="Grow Green SL (Pvt) Ltd" />
                          <div className="portfolio-info">
                              <h4>Coconut husks chips</h4>
                              <Link to={"/product/cocohuskchips"} className=" preview-link" ><i className="bx bx-plus"></i> </Link>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6 portfolio-item ">
                          <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/cocopeatblocks/t.jpg" className="img-fluid tab-view-img" style={{ height: '281px !important' }} alt="Grow Green SL (Pvt) Ltd" />
                          <div className="portfolio-info">
                              <h4>Coconut peat blocks</h4>
                              <Link to={"/product/cocopeatblocks"} className=" preview-link" ><i className="bx bx-plus"></i> </Link>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6 portfolio-item ">
                          <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/products/Planterbags/y.png" className="img-fluid tab-view-img" style={{ height: '281px !important' }} alt="Grow Green SL (Pvt) Ltd" />
                          <div className="portfolio-info">
                              <h4>Planter bags</h4>
                              <Link to={"/product/planterbags"} className=" preview-link" ><i className="bx bx-plus"></i> </Link>
                          </div>
                      </div>
                  </div>
              </TabPanel>
              <TabPanel>
                  <div className="row portfolio-container" >
                      <div className="col-lg-12 col-md-6 portfolio-item ">
                          <p style={{ textAlign: 'justify', fontWeight: 'bold' }}>

                              Experience the rich and authentic flavors of Ceylon spices with our premium quality products. Sourced from tropics of Sri Lanka, our Ceylon spices are free from any additives or preservatives. From cinnamon to clove, pepper to cardamom, our range of Ceylon spices is perfect for enhancing the taste of your favorite dishes. Order now and enjoy the unique and aromatic flavors of Ceylon spices!
                          </p>
                      </div>
                      <div className="col-lg-4 col-md-6 portfolio-item ">
                          <img src="https://m.media-amazon.com/images/I/712jSUsRETL.jpg" className="img-fluid tab-view-img" style={{ height: '281px !important' }} alt="Grow Green SL (Pvt) Ltd" />
                          <div className="portfolio-info">
                              <h4>Cinnamon Products</h4>

                              <Link to={"/cinnamon"} className=" preview-link" ><i className="bx bx-plus"></i> </Link>

                          </div>
                      </div>

                      <div className="col-lg-4 col-md-6 portfolio-item ">
                          <img src="https://static.tudo.lk/uploads/2022/06/16546866125438281.webp" className="img-fluid tab-view-img" style={{ height: '281px !important' }} alt="Grow Green SL (Pvt) Ltd" />
                          <div className="portfolio-info">
                              <h4>Black Pepper</h4>

                              <Link to={"/product/blackpapper"} className=" preview-link" ><i className="bx bx-plus"></i> </Link>

                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6 portfolio-item ">
                          <img src="https://images.herzindagi.info/image/2020/Jul/cloves-laung-for-health-benefits.jpg" className="img-fluid tab-view-img" style={{ height: '281px !important' }} alt="Grow Green SL (Pvt) Ltd" />
                          <div className="portfolio-info">
                              <h4>Clove</h4>

                              <Link to={"/product/clove"} className=" preview-link" ><i className="bx bx-plus"></i> </Link>

                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6 portfolio-item ">
                          <img src="https://cdn.i-scmp.com/sites/default/files/d8/images/canvas/2023/01/23/53e9e78a-e43e-4763-85a4-54e8f4a0ccb3_c4415be3.jpg" className="img-fluid tab-view-img" style={{ height: '281px !important' }} alt="Grow Green SL (Pvt) Ltd" />
                          <div className="portfolio-info">
                              <h4>Cardamom</h4>

                              <Link to={"/product/cardamom"} className=" preview-link" ><i className="bx bx-plus"></i> </Link>

                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6 portfolio-item ">
                          <img src="https://cdn.myanmarseo.com/file/client-cdn/gnlm/wp-content/uploads/2022/12/paNLcaSz-1-sskm-1.jpg" className="img-fluid tab-view-img" style={{ height: '281px !important' }} alt="Grow Green SL (Pvt) Ltd" />
                          <div className="portfolio-info">
                              <h4>Areca Nuts</h4>

                              <Link to={"/product/arecanuts"} className=" preview-link" ><i className="bx bx-plus"></i> </Link>

                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6 portfolio-item ">
                          <img src="https://img.tradeford.com/pimages/l/8/997598.jpg" className="img-fluid tab-view-img" style={{ height: '281px !important' }} alt="Grow Green SL (Pvt) Ltd" />
                          <div className="portfolio-info">
                              <h4>Garcinia</h4>

                              <Link to={"/product/garcinia"} className=" preview-link" ><i className="bx bx-plus"></i> </Link>

                          </div>
                      </div>
                  </div>
              </TabPanel>
              <TabPanel>
                  <img className='img-fluid' src={backgroundImage}></img>

              </TabPanel>
              <TabPanel>
                  <img className='img-fluid' src={backgroundImage}></img>
              </TabPanel>
              <TabPanel>
                  <img className='img-fluid' src={backgroundImage}></img>
              </TabPanel>
          </Tabs>
      </div>
      <WhatsAppWidget  phoneNumber="+94704000191" />

  </main>  <Footer /> </div>) }
           
           
        </div>
    );

}


export default WhatWeDo;