import React, { useState, useEffect, useRef } from 'react'
import { email } from '../../controller/emailsent'
import Header from '../shared/hearder'
import Footer from '../shared/footer'
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { FilePond, File, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import Dropzone from 'react-dropzone';
import emailjs from 'emailjs-com';
// import S3 from 'react-aws-s3';
import { S3 } from 'aws-sdk';
import { Alert } from 'antd';
import JSONCon from '../singleProducts/config.json'
window.Buffer = window.Buffer || require("buffer").Buffer;
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)
const config = {
    bucketName: JSONCon.bucketName,
    // dirName: 'contact', /* optional */
    region: JSONCon.region,
    accessKeyId: JSONCon.accessKeyId,
    secretAccessKey: JSONCon.secretAccessKey,
    // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
}
const s3 = new S3(config);
function ContactUs() {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1500);
        window.scrollTo(0, 0)
    }, [])
    const form = useRef();
    const [files, setFiles] = useState([])
    const [attachment, setAttachment] = useState(null);
    const [fileLocation, setFileLocation] = useState('');
    const [senderEmail, setsenderEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [name, setName] = useState('');
    const [sendDis, setSendDis] = useState(false);
    const [message, setMessage] = useState('');
    const [uploadDetails, setUploadDetails] = React.useState({});

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.init("hWdZSxCGrHgK2oik0");
        const emailService = "service_y1zevkp";
        const emailTemplate = "template_n0te9je";
        emailjs.sendForm(emailService, emailTemplate, form.current, "hWdZSxCGrHgK2oik0")
            .then(
                (result) => {
                    console.log('Email sent successfully', result.text);
                    // Reset the form
                    setsenderEmail('');
                    setName('');
                    setSubject('');
                    setContactNumber('');
                    setMessage('');
                    setAttachment(null);
                    setFiles([])
                    setFileLocation('')
                },
                (error) => {
                    console.error('Email could not be sent:', error.text);
                }
            );
    };
    const handleFileUpload = async (file) => {
        try {
            const uniqueFileName = Date.now() + '_' + file.name;
            setSendDis(true)
            const params = {
                Bucket: config.bucketName,
                Key: uniqueFileName,
                Body: file,
            };
            const uploadResult = await s3.upload(params).promise();
            if (uploadResult) {
                setUploadDetails({
                    key: uploadResult.Key,
                    location: uploadResult.Location
                })
                setFileLocation(uploadResult.Location)
                setSendDis(false)
            }
            setSendDis(false)
        } catch (error) {
        }
    };

    return (
        <div>

            <Header />
            {loading ? (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            ) : (
                <div>

                    <main id="main">



                        <div className="map-section">
                            <iframe style={{ border: '0', width: '100%', height: '350px' }} src="https://maps.google.com/maps?q=58/2,%20Meda%20Welikada%20Road&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" allowFullScreen></iframe>
                        </div>

                        <section id="contact" className="contact">
                            <div className="container">

                                <div className="row justify-content-center" data-aos="fade-up">
                                    <div className="col-lg-12 pt-4 pt-lg-0 mb-4" data-aos="fade-left">
                                        <h2 className='mb-2'>CONTACT US </h2>
                                        <p style={{ textAlign: 'justify' }} className="mt-2">
                                            <b> We value your feedback and appreciate your business.            </b>
                                        </p>
                                        <p style={{ textAlign: 'justify' }}>
                                            As a one-stop-shop for the highest quality coir and spices products in Sri Lanka, we take pride in our commitment to provide top-notch services to our customers. Our team of experts is always ready to assist you with any queries or suggestions you may have.

                                        </p>
                                        <p style={{ textAlign: 'justify' }}>
                                            We believe in establishing strong relationships with our customers based on trust, honesty, and transparency.
                                        </p>
                                        <p style={{ textAlign: 'justify' }}>
                                            Whether you have a question about our products or services or need help placing an order, we are always available to help. You can contact us through phone, email, or our website's contact form. We will respond to your inquiry as quickly as possible and provide you with the information you need.
                                        </p>
                                        <p style={{ textAlign: 'justify' }}>
                                            Our goal is to ensure that you are completely satisfied with our products and services. If you have any concerns or complaints, we encourage you to let us know. We take all feedback seriously and will work to resolve any issues as quickly as possible.                                    </p>

                                    </div>
                                    <div className="col-lg-12">

                                        <div className="info-wrap">
                                            <div className="row">
                                                <div className="col-lg-4 info">
                                                    <i className="icofont-google-map"></i>
                                                    <h4>Location:</h4>
                                                    <p> No.9D/178, Jayawadanagma<br />Battaramulla, Sri Lanka</p>
                                                </div>

                                                <div className="col-lg-4 info mt-4 mt-lg-0">
                                                    <i className="icofont-envelope"></i>
                                                    <h4>Email:</h4>
                                                    <p>info@growgreensl.com<br /></p>
                                                </div>

                                                <div className="col-lg-4 info mt-4 mt-lg-0">
                                                    <a href="tel:+94704000191"> <i className="icofont-phone"></i>
                                                        <h4>Call:</h4>
                                                        <p> +94 70 4000 191 <br /></p></a>
                                                    {/* <p> +94777-554056<br /></p> */}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <WhatsAppWidget phoneNumber="+94704000191" />

                                <div className="row mt-5 justify-content-center" data-aos="fade-up">
                                    <div className="col-lg-12">
                                        <p style={{ textAlign: 'justify' }} className="mt-4">
                                            <b> Contact us today to experience the Grow Green SL (Pvt) Ltd difference.             </b>
                                        </p>
                                        <form className="php-email-form" onSubmit={sendEmail} ref={form}>
                                            <div className="form-row">
                                                <div className="col-md-6 form-group">
                                                    <input id="name"
                                                        value={name} onChange={(e) => setName(e.target.value)} type="text" name="name" className="form-control" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                                                    <div className="validate"></div>
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <input id="senderEmail"
                                                        value={senderEmail} onChange={(e) => setsenderEmail(e.target.value)} name="senderEmail" type="email" className="form-control" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />

                                                    <div className="validate"></div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <input id="subject" name="subject"
                                                    value={subject} onChange={(e) => setSubject(e.target.value)} type="text" className="form-control" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                                                <div className="validate"></div>
                                            </div>
                                            <div className="form-group">
                                                <input id="contactNumber" name="contactNumber"
                                                    value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} type="text" className="form-control" placeholder="Contact Number" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                                                <div className="validate"></div>
                                            </div>
                                            <div className="form-group">
                                                <textarea id="message"
                                                    value={message} onChange={(e) => setMessage(e.target.value)} className="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
                                                <div className="validate"></div>
                                            </div>
                                            <div className="form-group">
                                                <FilePond
                                                    files={files}
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    onupdatefiles={(fileItems) => {
                                                        if (fileItems.length > 0) {
                                                            handleFileUpload(fileItems[0].file);
                                                        }
                                                    }}

                                                    name="files"
                                                    labelIdle='Drag & Drop your files or <span className="filepond--label-action">Browse</span>'
                                                />

                                            </div>
                                            <div className="mb-3">
                                                <div className="loading">Loading</div>
                                                <div className="error-message"></div>
                                                <div className="sent-message">Your message has been sent. Thank you!</div>
                                            </div>
                                            {
                                                !sendDis && <div className="text-center"><button type="submit" >Send Message</button></div>
                                            }
                                            <input id="fileLocation" name="fileLocation"
                                                value={fileLocation} type="text" className="form-control" hidden placeholder="Contact Number" />

                                        </form>

                                    </div>

                                </div>

                            </div>
                        </section>

                    </main>
                    <Footer />
                </div>
            )}

        </div>
    );
}



export default ContactUs;