import React, { useState, useEffect } from 'react'
import './home.css'
import Header from '../shared/hearder'
import TransHeader from '../shared/tranHeader'
import Footer from '../shared/footer'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide, } from "swiper/react";
import { Autoplay } from "swiper";
import truk from '../../asserts/icon/truck.png'
import shield from '../../asserts/icon/shield.png'
import chat from '../../asserts/icon/chat.png'
import graph from '../../asserts/icon/graph.png'
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
const CardHover = ({ title, desc, img, link }) => {
  return (
    <div className="card-hover">
      <div className="card-hover__content">
        <h3 className="card-hover__title">
          {title}
        </h3>
        <p className="card-hover__text">{desc}</p>
        <Link to={link} className="card-hover__link">
          <span>View More</span>
          <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
          </svg>
        </Link>
      </div>
      <img className="img-card-new" src={img} alt="" />
    </div>
  );
};


function Home() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [])
  return (
    <div>

      <Header />
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div>
          <section id="hero" style={{ paddingTop: '20px' }}>
            <TransHeader />
            <div id="heroCarousel" className="carousel slide carousel-fade" data-ride="carousel">

              <div className="carousel-inner" role="listbox">

                <div className="carousel-item active slide_1">
                  <div className="carousel-container">
                    <div className="carousel-content-trans animated fadeInUp mx-2">
                      <div className="nav-container-trans">
                        <ul className="nav-tabs-trans">
                          <li className="nav-tab"> <Link to='/' className='cli_n-trans'>Home</Link></li>
                          <li className="nav-tab"><Link to='/aboutus' className='cli_n-trans'>About </Link></li>
                          <li className="nav-tab"><Link to='/products/all' className='cli_n-trans'>Our Products</Link></li>
                          <li className="nav-tab"><Link to='/services' className='cli_n-trans'>Our Services</Link></li>
                          <li className="nav-tab"><Link to='/contact-us' className='cli_n-trans'>Contact us</Link></li>
                        </ul>
                      </div>
                    </div>
                    <div className="carousel-content animated fadeInUp mx-2">
                      <h2>Welcome to <span>Grow Green SL (Pvt) Ltd</span></h2>
                      <p>A leading exporter of premium quality coir & spices from Sri Lanka. We embarked on a journey to establish a sustainable future by harnessing Sri Lanka's natural resources and sharing them globally.  </p>
                      <div ><Link to="/aboutus" className="btn-get-started">Read More</Link></div>
                    </div>
                  </div>
                </div>

                <div className="carousel-item slide_2" >
                  <div className="carousel-container">
                    <div className="carousel-content-trans animated fadeInUp mx-2">
                      <div className="nav-container-trans">
                        <ul className="nav-tabs-trans">
                          <li className="nav-tab"> <Link to='/' className='cli_n-trans'>Home</Link></li>
                          <li className="nav-tab"><Link to='/aboutus' className='cli_n-trans'>About </Link></li>
                          <li className="nav-tab"><Link to='/products/all' className='cli_n-trans'>Our Products</Link></li>
                          <li className="nav-tab"><Link to='/services' className='cli_n-trans'>Our Services</Link></li>
                          <li className="nav-tab"><Link to='/contact-us' className='cli_n-trans'>Contact us</Link></li>
                        </ul>
                      </div>
                    </div>
                    <div className="carousel-content animated fadeInUp">
                      <h2>Welcome to <span>Grow Green SL (Pvt) Ltd</span></h2>
                      <p>Our commitment to sustainable sourcing and ethical business practices enables us to offer the finest quality products to our customers worldwide. </p>
                      <div ><Link to="/aboutus" className="btn-get-started">Read More</Link></div>
                    </div>
                  </div>
                </div>

                <div className="carousel-item slide_3" >
                  <div className="carousel-container">
                    <div className="carousel-content-trans animated fadeInUp mx-2">
                      <div className="nav-container-trans">
                        <ul className="nav-tabs-trans">
                          <li className="nav-tab"> <Link to='/' className='cli_n-trans'>Home</Link></li>
                          <li className="nav-tab"><Link to='/aboutus' className='cli_n-trans'>About </Link></li>
                          <li className="nav-tab"><Link to='/products/all' className='cli_n-trans'>Our Products</Link></li>
                          <li className="nav-tab"><Link to='/services' className='cli_n-trans'>Our Services</Link></li>
                          <li className="nav-tab"><Link to='/contact-us' className='cli_n-trans'>Contact us</Link></li>
                        </ul>
                      </div>
                    </div>
                    <div className="carousel-content animated fadeInUp">
                      <h2>Welcome to <span>Grow Green SL (Pvt) Ltd</span></h2>
                      <p>Explore our range of coconut-based products and experience the natural goodness of Sri Lankan spices today.</p>
                      <div ><Link to="/aboutus" className="btn-get-started">Read More</Link></div>
                    </div>
                  </div>
                </div>
              </div>

              <a className="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon icofont-simple-left" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>

              <a className="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
                <span className="carousel-control-next-icon icofont-simple-right" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>

              <ol className="carousel-indicators" id="hero-carousel-indicators"></ol>
            </div>
          </section>

          <main id="main">
            <div id="scroll-down-animation">
              <span className="mouse">
                <span className="move"></span>
              </span>
            </div>

            <section id="about-us" className="about-us ">
              <div className="container" data-aos="fade-up">
                {/* 
            <div id='#aboutus' className="section-title">
              <h2>About us</h2>
            </div> */}

                <div className="row content">


                  <div className="col-md-6" data-aos="fade-right">

                    <h2 style={{ textTransform: 'unset' }} >Bringing Ceylon's finest to the world market</h2>
                    <h3>We are leading manufacturer & exporter of coir products & spices in  Sri Lanka. It's all about Quality, not Quantity.</h3>
                  </div>
                  <div className="col-md-6" data-aos="fade-right">

                    <div className='videoheader'>
                      <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
                        <source src='https://growgreencontact.s3.us-west-1.amazonaws.com/homepage.mp4' type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>

              </div>
            </section>

            <section id="services" className="services section-bg main-div-bk">
              <div className="container" data-aos="fade-up">

                <div className="section-title">
                  <h2>Our Products</h2>
                  <p>Finest products from Ceylon to your doorstep.</p>
                </div>

                <div className="row">
                  <div className="col-lg-4 col-md-6 d-flex align-items-stretch " data-aos="zoom-in" data-aos-delay="100">
                    <CardHover title={"Coco Products "} desc={"High quality and authentic coconut fiber products."} img={"https://growgreencontact.s3.us-west-1.amazonaws.com/cocop.png"} link={"/products/coco-products"} />
                  </div>



                  <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                    <CardHover title={"Ceylon Spice"} desc={"Finest Ceylon spices for a tastiest dish."} img={"https://growgreencontact.s3.us-west-1.amazonaws.com/spices.png"} link={"/products/spices"} />
                  </div>

                  <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
                    <CardHover title={"Pre-Cooked Foods"} desc={"Freshly prepared and hygienically packed for you"} img={"https://growgreencontact.s3.us-west-1.amazonaws.com/prec.png"} link={"/products/pre-cooked"} />
                  </div>

                  <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
                    <CardHover title={"Fruits"} desc={"Don't miss the best Ceylon fruits with the best quality."} img={"https://growgreencontact.s3.us-west-1.amazonaws.com/fruits.png"} link={"/products/fruits"} />
                  </div>

                  <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
                    <CardHover title={"Vegetables"} desc={"Eat green stay healthier, fresh Ceylon vegetables for you."} img={"https://growgreencontact.s3.us-west-1.amazonaws.com/vegitables.png"} link={"/products/vegetables"} />
                  </div>
                </div>

              </div>
            </section>
            <section id="faq" className="faq section-bg">
              <div className="container" data-aos="fade-up">

                <div className="section-title">
                  <h2>Frequently Asked Questions</h2>
                </div>

                <div className="faq-list">
                  <ul>
                    <li data-aos="fade-up">
                      <i className="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" className="collapse" href="#faq-list-1">How Do I Make a Purchase? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                      <div id="faq-list-1" className="collapse show" data-parent=".faq-list">
                        <p>
                          You are going to make a great choice! please go to the contact us section and fill out the form with the requirement.
                        </p>
                      </div>
                    </li>

                    <li data-aos="fade-up" data-aos-delay="100">
                      <i className="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-2" className="collapsed">Are these products manufactured in Sri Lanka? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                      <div id="faq-list-2" className="collapse" data-parent=".faq-list">
                        <p>
                          Yes of course! All the coconut and spice raw materials directly come to us from our Sri Lankan coconut and spices farmers.
                        </p>
                      </div>
                    </li>

                    <li data-aos="fade-up" data-aos-delay="200">
                      <i className="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-3" className="collapsed">Can I order bulk amounts? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                      <div id="faq-list-3" className="collapse" data-parent=".faq-list">
                        <p>
                          Yes, we can customize your order based on any kind of industrial purpose.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

              </div>
            </section>
            <section id="faq" className="faq ">
              <div className="container-fluid" data-aos="fade-up">

                <div className='row'>
                  <div className='col-md-3 '>
                    <div className='why-us-card'>
                      <img src={truk} className='img-fluid why-us-img' alt='' />
                      <h4 className='why-us-h4'>WORLDWIDE DELIVERY</h4>
                      <p>We offer both air and sea shipping options to ensure our premium spices can reach you no matter where you are located.</p>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='why-us-card'> <img src={chat} className='img-fluid why-us-img' alt='' />
                      <h4 className='why-us-h4'>24/7 CHAT SYSTEM</h4>
                      <p>Need help? Our friendly support team is available round the clock to answer any questions.</p></div>

                  </div>
                  <div className='col-md-3'>
                    <div className='why-us-card'> <img src={shield} className='img-fluid why-us-img' alt='' />
                      <h4 className='why-us-h4'>SUSTAINABLE PRACTICES</h4>
                      <p>We take pride in our sustainable business practices, including sourcing spices from fair trade suppliers and using eco-friendly packaging.</p></div>

                  </div>
                  <div className='col-md-3'>
                    <div className='why-us-card'> <img src={graph} className='img-fluid why-us-img' alt='' />
                      <h4 className='why-us-h4'>COMPANY GROWTH</h4>
                      <p>With a passion for spices and a commitment to quality, our company has seen rapid growth and a loyal customer base.</p></div>

                  </div>
                </div>

              </div>
            </section>
            <section id="faq" className="faq section-bg">
              <div className="container-fluid" data-aos="fade-up">

                <div className="section-title">
                  <h2>Testimonials</h2>
                </div>

                <Swiper
                  breakpoints={{
                    640: {
                      width: 640,
                      slidesPerView: 1,
                    },
                    768: {
                      width: 768,
                      slidesPerView: 2,
                    },
                    1200: {
                      width: 1200,
                      slidesPerView: 3,
                    },
                    1800: {
                      width: 1800,
                      slidesPerView: 4,
                    },
                  }}

                  spaceBetween={10}
                  centeredSlides={true}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}

                >
                  <SwiperSlide>
                    <figure className="snip1533">
                      <figcaption>
                        <blockquote>
                          <p>As a retailer specializing in organic products, I've partnered with Grow Green SL (Pvt) Ltd to offer my customers the best quality spices on the market. Their commitment to organic farming and fair trade practices is unparalleled. </p>
                        </blockquote>
                        <h3>Peter S.</h3>
                        <h4>Organic Retailer, Australia </h4>
                      </figcaption>
                    </figure>                                        </SwiperSlide>
                  <SwiperSlide>
                    <figure className="snip1533">
                      <figcaption>
                        <blockquote>
                          <p>I've been sourcing spices from Grow Green SL (Pvt) Ltd for my restaurant, and I am extremely impressed with the quality and freshness of their products. It reflects in the incredible flavors their spices bring to my dishes.</p>
                        </blockquote>
                        <h3>Chef Melissa R.</h3>
                        <h4>Restaurant Owner</h4>
                      </figcaption>
                    </figure>                                        </SwiperSlide>
                  <SwiperSlide>

                    <figure className="snip1533">
                      <figcaption>
                        <blockquote>
                          <p>Grow Green SL (Pvt) Ltd has been a game-changer for my organic farming endeavors. Their coir pots and grow bags are perfect for nurturing my organic vegetables and herbs.</p>
                        </blockquote>
                        <h3>David L.</h3>
                        <h4>Organic Farmer</h4>
                      </figcaption>
                    </figure>                                        </SwiperSlide>
                  <SwiperSlide>
                    <figure className="snip1533">
                      <figcaption>
                        <blockquote>
                          <p>As a landscape designer, I always prioritize using eco-friendly materials in my projects. Working with Grow Green SL (Pvt) Ltd has been a pleasure, as they consistently deliver top-notch products that meet my requirements.</p>
                        </blockquote>
                        <h3>Michael L.</h3>
                        <h4>Landscape Designer</h4>
                      </figcaption>
                    </figure>                                        </SwiperSlide>
                  <SwiperSlide>
                    <figure className="snip1533">
                      <figcaption>
                        <blockquote>
                          <p>I appreciate the quality and versatility of their coir products, and the positive feedback I receive from my clients speaks volumes about the reliability of Grow Green SL (Pvt) Ltd. </p>
                        </blockquote>
                        <h3>Anne Heathway</h3>
                        <h4>Customer </h4>
                      </figcaption>
                    </figure>                                        </SwiperSlide>
                  <SwiperSlide>

                    <figure className="snip1533">
                      <figcaption>
                        <blockquote>
                          <p>The coir blocks and grow bags provide excellent moisture retention and root support for my plants. The sustainability factor is a bonus. Grow Green SL (Pvt) Ltd is a trusted choice for hydroponic enthusiasts like me.  </p>
                        </blockquote>
                        <h3>Lisa H.</h3>
                        <h4>Hydroponics Practitioner</h4>
                      </figcaption>
                    </figure>                                        </SwiperSlide>

                </Swiper>





              </div>
            </section>
            <section id="portfolio" className="portfolio">
              <div className="container">

                <div className="section-title" data-aos="fade-up">
                  <h2>Gallery</h2>
                </div>

                <WhatsAppWidget phoneNumber="+94704000191" />


                <div className="row portfolio-container" data-aos="fade-up">



                  <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                    <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/6.jpg" className="img-fluid zoom" alt="Grow Green SL (Pvt) Ltd" />

                  </div>

                  <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                    <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/7.jpg" className="img-fluid zoom" alt="Grow Green SL (Pvt) Ltd" />

                  </div>

                  <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                    <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/15.jpg" className="img-fluid zoom" alt="Grow Green SL (Pvt) Ltd" />

                  </div>
                  <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                    <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/29.jpg" className="img-fluid zoom" alt="Grow Green SL (Pvt) Ltd" />

                  </div>

                  <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                    <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/30.jpg" className="img-fluid zoom" alt="Grow Green SL (Pvt) Ltd" />

                  </div>

                  <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                    <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/31.jpg" className="img-fluid zoom" alt="Grow Green SL (Pvt) Ltd" />

                  </div>
                  <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                    <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/32.jpg" className="img-fluid zoom" alt="Grow Green SL (Pvt) Ltd" />

                  </div>
                  <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                    <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/34.jpg" className="img-fluid zoom" alt="Grow Green SL (Pvt) Ltd" />

                  </div>
                  <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                    <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/33.jpg" className="img-fluid zoom" alt="Grow Green SL (Pvt) Ltd" />

                  </div>
                  <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                    <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/35.jpg" className="img-fluid zoom" alt="Grow Green SL (Pvt) Ltd" />

                  </div>
                  <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                    <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/36.jpg" className="img-fluid zoom" alt="Grow Green SL (Pvt) Ltd" />

                  </div>
                  <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                    <img src="https://growgreencontact.s3.us-west-1.amazonaws.com/37.jpg" className="img-fluid zoom" alt="Grow Green SL (Pvt) Ltd" />

                  </div>

                </div>

              </div>
            </section>
          </main>
          <Footer />
        </div>
      )}


    </div>
  );
}


export default Home;